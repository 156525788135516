import { t } from 'i18next'
import React from 'react'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { FetchDomains, checkedItems } from '../../types'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allDomains?: FetchDomains
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
}

const DomainRequestTable = ({ checkedItems, setCheckedItems, allDomains }: TableProps) => {
  const handleCheckAll = () => {
    if (allDomains?.allDomains.length !== checkedItems.length) {
      allDomains !== undefined && setCheckedItems(allDomains?.allDomains.map(domain => ({ id: domain.store.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-4 text-sm font-normal text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allDomains?.allDomains.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('store')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('status')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('price')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('domain')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('type')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {allDomains?.allDomains.map((domain, index) => (
          <tr key={index}>
            <td className="px-3 py-4 text-sm font-medium">
              <div className='flex'>
                <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === domain.store.id)} onChange={() => handleCheck(domain.store.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </td>

            <td className="px-3 py-3 text-sm">
              <h2 className="text-gray-800">{domain.store !== null ? domain.store.name : "N/A"}</h2>
            </td>

            {<td className="px-3 py-4 text-sm">
              {domain.status === "pending" &&
                <p className="p-2 font-semibold text-orange-500 bg-orange-100 rounded-full w-fit">{t('pending')}</p>
              }
              {/* <p className="p-2 font-semibold text-red-500 bg-red-100 rounded-full w-fit">{t('canceled')}</p> 
              <p className="p-2 font-semibold text-green-500 bg-green-100 rounded-full w-fit">{t('done')}</p>  */}
            </td>}

            <td className="px-3 py-4 text-sm">
              <p className="text-gray-500">${domain.price / 1000000}</p>
            </td>

            <td className="px-3 py-3 text-sm whitespace-nowrap">
              <p className="text-gray-500">{domain.domain || "N/A"}</p>
            </td>

            <td className="px-3 py-3 text-sm whitespace-nowrap">
              <p className="text-gray-500">
                { 
                  domain.loginInformation === null ?
                    t('customDomain')
                    :
                    t('domainOrder')
                }
              </p>
            </td>

            <td className="px-3 py-4 text-sm">
              <div className='flex justify-center'>
                <button className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <AiOutlineAppstoreAdd size={20} />

                  <span className="mx-2">
                    {t('connect')}
                  </span>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DomainRequestTable