import { gql, useLazyQuery } from "@apollo/client"
import { FetchUser } from "../../types";

export const USER = gql`
query User($userId: ID) {
  user(id: $userId) {
    id
    profilePicture
    name
    email
    phone
    role
    activation
    emailVerify
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useUser = () => {
  return useLazyQuery<FetchUser>(USER);
}