import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { FetchCountries, StoresProps } from '../../types';
import { ALL_COUNTRIES, CreatePaymentMethod } from '../../graphql';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { MdClose } from 'react-icons/md';
import { t } from 'i18next';

interface Props {
  setAddPaymentPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedStore: StoresProps | null,
}

const AddPaymentPopup = ({ setAddPaymentPopup, selectedStore }: Props) => {

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedBank, setSelectedBank] = useState("");

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [IBAN, setIBAN] = useState("");

  const [countriesDrop, setCountriesDrop] = useState(false);

  const { data: allCountries, loading } = useQuery<FetchCountries>(ALL_COUNTRIES, {
    variables: { idStore: selectedStore?.id }
  });

  // Create
  const [createPayment, { loading: createLoading, error }] = useMutation(CreatePaymentMethod, {
    update: (cache, { data: { createPaymentMethod } }) => {
      cache.modify({
        fields: {
          allPaymentMethods(existedPaymentMethods = []) {
            return [...existedPaymentMethods, createPaymentMethod];
          }
        }
      })
    }
  });

  // Drops ref's
  const countriesRef = useRef<any>();
  const bankRef = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (countriesRef.current || bankRef.current) {
        if (countriesRef.current.contains(e.target as Node) || bankRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCountriesDrop(false);
      }
    })
  }, [])

  const submit = async (e: any) => {
    e.preventDefault();

    if (selectedCountry === "") {
      setFailMsg(t("countryRequire"));
      setFail(true);
      return
    }
    if (selectedBank === "") {
      setFailMsg(t('emptyBank'));
      setFail(true);
      return
    }

    if (name === "") {
      setFailMsg(t('NameRequire'));
      setFail(true);
      return
    }
    if (number === "") {
      setFailMsg(t('emptyNumber'));
      setFail(true);
      return
    }
    if (IBAN === "") {
      setFailMsg(t('emptyIBAN'));
      setFail(true);
      return
    }

    await createPayment({
      variables: {
        content: {
          IBAN: IBAN,
          accountName: name,
          bankName: selectedBank,
          idCountry: selectedCountry.id,
          number: number,
          idStore: selectedStore?.id
        }
      }
    })


    if (!error) {
      setSuccessMsg(t('createSuccess'));
      setSuccess(true);

      // CLear the form
      setSelectedCountry("");
      setSelectedBank("");
      setName("");
      setNumber("");
      setIBAN("");

      setAddPaymentPopup(false)
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 overflow-y-auto bg-white border h-[80vh] rounded-md sm:w-4/6 md:w-3/6'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddPaymentPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='my-3'>
          {
            createLoading &&
            <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 bg-white/70'>
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div>
          }

          <div className="relative mb-2" ref={countriesRef}>
            <button onClick={(e) => { e.preventDefault(); setCountriesDrop(!countriesDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
              <span>
                {selectedCountry !== "" ? selectedCountry.name : t('select')}
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
              </svg>
            </button>
            {countriesDrop &&
              <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                {loading ?
                  <LoadingWidget />
                  :
                  <div>
                    {
                      allCountries?.allCountries.length === 0 ?
                        <div className='px-3'>
                          {t('noCountries')}
                        </div>
                        :
                        allCountries?.allCountries.map((country => {
                          return (
                            <div key={country.id} onClick={() => { setSelectedCountry(country); setCountriesDrop(false) }
                            }>
                              <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                <span className="mx-1">
                                  {country.name}
                                </span>
                              </div>
                            </div>
                          )
                        }))}
                  </div>
                }
              </div>
            }
          </div>

          <div className="mb-2" ref={bankRef}>
            <label htmlFor="bank" className="block mb-2 text-sm font-medium text-gray-900">{t('Bank')}</label>
            <input type="text" id="bank" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('Bank')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('name')}</label>
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('accountHolderName')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900">{t('accountNumber')}</label>
            <input type="text" id="number" value={number} onChange={(e) => setNumber(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('accountNumber')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="IBAN" className="block mb-2 text-sm font-medium text-gray-900">IBAN</label>
            <input type="text" id="IBAN" value={IBAN} onChange={(e) => setIBAN(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='IBAN' required />
          </div>
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default AddPaymentPopup