import { gql } from "@apollo/client"

export const CreateProduct = gql`
mutation CreateProduct($content: contentProduct!) {
  createProduct(content: $content) {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    productOptions {
      id
      name
      subOptions {
        id
        name
        price
        quantity
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`