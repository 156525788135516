import React, { useEffect, useState } from 'react'
import OrdersTable from '../../components/Tables/OrdersTable';
import { DeliveryProps, FetchDeliveries, checkedItems } from '../../types';
import { useAllDeliveries } from '../../graphql';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import { t } from 'i18next';

const Orders = ({ direction }: { direction: string }) => {

  // The state that contains Fetched Orders, i didn't use dataAllDeliveries from useAllDeliveries beacuse i couldn't modify it to contain filtered result
  const [Deliveries, setDeliveries] = useState<FetchDeliveries>();

  const [deleteConfirmPopup, setDeleteConfPopup] = useState(false);

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  // i had to use a main state for controle loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allDeliveries, { loading, data: dataAllDeliveries }] = useAllDeliveries();

  useEffect(() => {
    (async () => {
      await allDeliveries();

      setInitialLoading(false)
    })()

  }, []);

  useEffect(() => {
    dataAllDeliveries !== undefined && setDeliveries(dataAllDeliveries)
  }, [dataAllDeliveries]);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  const [FilterDropDown, setFilterDropDown] = useState(false);

  const [PaymentFilterDropDown, setPaymentFilterDropDown] = useState(false);
  const [PaymentFilterQuery, setPaymentFilterQuery] = useState("");
  const [ShippingFilterDropDown, setShippingFilterDropDown] = useState(false);
  const [ShippingFilterQuery, setShippingFilterQuery] = useState("");

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<DeliveryProps[] | undefined>([]);

  useEffect(() => {
    const filtered = dataAllDeliveries?.deliveriesAllStores.filter(order =>
      order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResult(filtered);
  }, [searchQuery, dataAllDeliveries]);

  // Find the available payment Methods
  const uniquePaymentSet = new Set(dataAllDeliveries?.deliveriesAllStores.map(order => order?.paymentMethod));
  const uniquePayment = Array.from(uniquePaymentSet);

  // Find the available shipping Methods
  const uniqueShippingSet = new Set(dataAllDeliveries?.deliveriesAllStores.map(order => order?.deliveryMethod?.name));
  const uniqueShipping = Array.from(uniqueShippingSet);

  useEffect(() => {
    if (PaymentFilterQuery !== "" && dataAllDeliveries !== undefined) {
      const filtered = dataAllDeliveries?.deliveriesAllStores.filter(item =>
        item.paymentMethod.bankName.toLowerCase().includes(PaymentFilterQuery.toLowerCase())
      );

      setDeliveries({ deliveriesAllStores: filtered });
    }
  }, [PaymentFilterQuery, dataAllDeliveries])

  useEffect(() => {
    if (ShippingFilterQuery !== "" && dataAllDeliveries !== undefined) {
      const filtered = dataAllDeliveries?.deliveriesAllStores.filter(item =>
        item.deliveryMethod.name === ShippingFilterQuery
      );

      setDeliveries({ deliveriesAllStores: filtered });
    }
  }, [ShippingFilterQuery, dataAllDeliveries]);

  const clearFilters = () => {
    dataAllDeliveries !== undefined && setDeliveries(dataAllDeliveries)
    setPaymentFilterQuery("");
    setShippingFilterQuery("");
  }

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfirmPopup && <DeleteConfirmPopup checkedItems={checkedItems} selectedIdForDelete={selectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('orders')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        dataAllDeliveries?.deliveriesAllStores.length === 0 ?
          <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
              <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
            </svg>
            <span className='px-2'>{('noOrders')}</span>
          </div>
          :
          <div className='p-2 mt-3 bg-white rounded-md'>
            <div>
              <div className='my-4 md:flex md:justify-between'>
                <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
                  <div className="grid w-12 h-full text-gray-300 place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>

                  <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />
                </div>

                <div className='gap-3 sm:flex'>

                  <button onClick={() => { setFilterDropDown(!FilterDropDown) }} className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583-5.71 5.71z"></path>
                    </svg>

                    <span className="mx-2">
                      {t('filter')}
                    </span>
                  </button>

                  <Link to="/orders/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                      <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                    </svg>

                    <span className="mx-2">
                      {t('Add Orders')}
                    </span>
                  </Link>
                </div>
              </div>

              {FilterDropDown &&
                <div>
                  <div className='grid grid-cols-2 gap-2'>
                    <div className="relative inline-block">
                      <button onClick={() => { setPaymentFilterDropDown(!PaymentFilterDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                        <span className="mx-1">{t('Payment')}</span>
                        <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                        </svg>
                      </button>

                      {PaymentFilterDropDown &&
                        <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                          {uniquePayment.map((payment: any, index) => (
                            <span key={index} onClick={() => { setPaymentFilterQuery(payment); setPaymentFilterDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                              {payment}
                            </span>
                          ))}
                        </div>
                      }
                    </div>

                    <div className="relative inline-block">
                      <button onClick={() => { setShippingFilterDropDown(!ShippingFilterDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                        <span className="mx-1">{t('shipping')}</span>
                        <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                        </svg>
                      </button>

                      {ShippingFilterDropDown &&
                        <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                          {uniqueShipping.map((shipping: any, index) => (
                            <span key={index} onClick={() => { setShippingFilterQuery(shipping); setShippingFilterDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                              {shipping}
                            </span>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                  <div className='flex justify-end'>
                    <button onClick={clearFilters} className='px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                      {t('clear')}
                    </button>
                  </div>
                </div>
              }
            </div>

            <div className='h-full pt-2 overflow-x-scroll'>
              <OrdersTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} searchQuery={searchQuery} searchResult={searchResult} allDeliveries={Deliveries} startingIndex={startingIndex} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfirmPopup={setDeleteConfPopup} />
            </div>

            {searchQuery !== "" &&
              searchResult !== undefined &&
              searchResult.length === 0 &&
              <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                  <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                </svg>
                <span className='px-2'>{t('notMatch')}</span>
              </div>
            }

            <div className="flex justify-end gap-1.5 text-gray-500 py-4">
              <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
              <span>{t('of')} {dataAllDeliveries?.deliveriesAllStores.length} {t('records')}</span>
            </div>

            <div dir={"ltr"} className='flex items-center justify-between'>
              {dataAllDeliveries !== undefined &&
                startingIndex !== 0 &&
                <div className="gap-1.5 text-gray-500 py-2">
                  <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <IoArrowBackSharp size={18} />
                    <span className="mx-2">
                      {t('prev')}
                    </span>
                  </button>
                </div>
              }

              {dataAllDeliveries !== undefined &&
                startingIndex + 10 < dataAllDeliveries?.deliveriesAllStores.length &&
                <div className="ml-auto gap-1.5 text-gray-500 py-2">
                  <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <IoArrowForward size={18} />
                    <span className="mx-2">
                      {t('next')}
                    </span>
                  </button>
                </div>
              }
            </div>
          </div>
      }
    </section>
  )
}

export default Orders