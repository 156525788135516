import { gql } from "@apollo/client"

export const CreateDeliveryMethod = gql`
mutation Mutation($content: contentDeliveryMethod!) {
  createDeliveryMethod(content: $content) {
    id
    name
    price
    paymentWhenReceiving
    pricePaymentWhenReceiving
    estimatedTime
    createdAt
    updatedAt
    deletedAt
  }
}

`