import { gql } from "@apollo/client"

export const CreateAddressClient = gql`
mutation CreateAddressClient($content: contentAddressClient!) {
  createAddressClient(content: $content) {
    id
    name
    phone
    city
    address
    asDefault
    client {
      id
      name
      email
      phone
      dateBirth
      address
      gander
      note
      addressClient {
        address
        asDefault
        city
        id
        name
        phone
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
}
`