import React, { useEffect, useRef, useState } from 'react'
import { ClientsProps, StoreProps, addressClientProps } from '../../../types'
import { useAllClientsStore } from '../../../graphql';
import LoadingWidget from '../../LoadingWidget/LoadingWidget';
import { t } from 'i18next';

interface Tab2Props {
  prevPage: () => void,
  nextPage: () => void,
  addressClient: addressClientProps | null,
  setAddressClient: React.Dispatch<React.SetStateAction<addressClientProps | null>>,
  setAddAddressPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedClient: ClientsProps | null,
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientsProps | null>>
  selectedStore: StoreProps | null
}

const Tab2 = ({
  prevPage,
  nextPage,
  addressClient,
  setAddressClient,
  setAddAddressPopup,
  selectedClient,
  setSelectedClient,
  selectedStore
}: Tab2Props) => {
  const [clientsDrop, setClientsDrop] = useState(false);
  const selectClientRef = useRef<any>();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState<ClientsProps[] | undefined>([]);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allClients, { loading, data: dataAllClients }] = useAllClientsStore();

  useEffect(() => {
    selectedStore &&
      allClients({
        variables: { idStore: selectedStore.id }
      })

    setInitialLoading(false)
  }, [selectedStore]);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (selectClientRef.current) {
        if (selectClientRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setClientsDrop(false);
      }
    })
  }, [])

  const selectClient = (client: any) => {

    setSelectedClient(client);

    if (client.addressClient !== null) {
      if (client.addressClient.length > 0) {
        setAddressClient(client.addressClient[0]);
      }
    }

    setClientsDrop(false)
  }

  useEffect(() => {
    if (selectedStore) {
      const filtered = dataAllClients?.allClient?.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setSearchResult(filtered);
    }
  }, [searchQuery, dataAllClients, selectedStore]);

  return (
    <div>
      <div className='mt-4'>
        <div className="mb-3">
          <label htmlFor="cost" className="block mb-2 text-sm font-medium text-gray-900">{t('client')}</label>

          <div className="relative" ref={selectClientRef}>
            <button onClick={(e) => { e.preventDefault(); setClientsDrop(!clientsDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
              <span>
                {selectedClient === null ? t("select") : selectedClient?.name}
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
              </svg>
            </button>
            {clientsDrop &&
              <div className="absolute right-0 z-20 w-full pb-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                {initialLoading || loading ?
                  <LoadingWidget />
                  :
                  <>
                    <div className="w-full p-3 border-b">
                      <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />
                    </div>
                    {searchQuery === "" ?
                      dataAllClients?.allClient.map(client => (
                        <div key={client.id} onClick={() => selectClient(client)} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          <span className="mx-1">
                            {client.name}
                          </span>
                        </div>
                      ))
                      :
                      searchResult?.map(client => (
                        <div key={client.id} onClick={() => selectClient(client)} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          <span className="mx-1">
                            {client.name}
                          </span>
                        </div>
                      ))
                    }

                    {dataAllClients?.allClient.length === 0 &&
                      <div className='px-2 pt-1'>
                        {t('No Clients to show')}
                      </div>
                    }

                    {searchQuery !== "" &&
                      searchResult !== undefined &&
                      searchResult.length === 0 &&
                      <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                          <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                        </svg>
                        <span className='px-2'>{t('notMatch')}</span>
                      </div>
                    }
                  </>
                }
              </div>
            }
          </div>

          <div>
            {selectedClient !== null &&
              <div className='grid grid-cols-3 gap-3 my-5'>
                {selectedClient?.addressClient !== null &&
                  <>
                    {selectedClient?.addressClient.map(address => (
                      <div key={address.id} onClick={() => { setAddressClient(address) }} className={`px-5 py-2 border ${addressClient?.id === address.id && 'border-rose-600'} rounded-md cursor-pointer`}>
                        <div className='flex justify-end '>
                          <div className='w-4 h-4 mb-2 overflow-hidden border border-red-500 rounded-full'>
                            <div className='w-full h-full p-0.5 overflow-hidden bg-white rounded-full'>
                              <div className={`w-full h-full ${address.id === addressClient?.id && "bg-red-500"} rounded-full`}></div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-rose-100 text-rose-600 inline-block p-2.5 rounded-full mt-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path><path d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"></path>
                          </svg>
                        </div>
                        <p className='mt-2 text-lg font-semibold'>{address.city}</p>
                        <p className='text-sm text-gray-500'>{address.name}</p>
                      </div>
                    ))}
                  </>
                }

                <div onClick={() => setAddAddressPopup(true)} className='flex items-center justify-center gap-2 p-5 border rounded-md cursor-pointer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span>
                    {t('Add Address')}
                  </span>
                </div>
              </div>
            }
          </div>
        </div>

        <div className='flex justify-between my-2'>
          <div onClick={() => { prevPage() }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('prev')}
              </span>
            </button>
          </div>
          <div onClick={() => { nextPage() }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('next')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Tab2