import React, { useEffect, useState } from 'react'
import { FetchProductsStore, ProductProps, SelectedProductsProps, StoresProps } from '../../types'
import { useAllProductsStore } from '../../graphql'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import { MdClose } from 'react-icons/md'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { t } from 'i18next'

interface SelectProductPopupProps {
  setAddProductPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedProductsList: SelectedProductsProps[],
  setSelectedProductsList: React.Dispatch<React.SetStateAction<SelectedProductsProps[]>>,
  selectedStore: StoresProps | null
}

const SelectProductPopup = ({ setAddProductPopup, selectedProductsList, setSelectedProductsList, selectedStore }: SelectProductPopupProps) => {
  // The state that contains Fetched Products, i didn't use allProducts from useAllProducts because i couldn't modify it to contain filtered result
  const [Products, setProducts] = useState<FetchProductsStore>();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState<ProductProps[] | undefined>([]);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [allProducts, { loading, data: dataAllProducts }] = useAllProductsStore();

  useEffect(() => {
    selectedStore &&
      (async () => {
        await allProducts({
          variables: { idStore: selectedStore?.id }
        })

        setInitialLoading(false)
      })()

  }, [selectedStore]);

  useEffect(() => {
    selectedStore && dataAllProducts !== undefined && setProducts(dataAllProducts)
  }, [dataAllProducts, selectedStore]);

  const addProduct = (product: any) => {
    // Check if the product already selected
    const exists = selectedProductsList.some((prdct) => prdct.id === product.id);

    if (exists) {
      setFailMsg(t('Product Already Added'));
      setFail(true);
      return
    }

    setSuccessMsg(t('Product Added Success'));
    setSuccess(true);
    setSelectedProductsList([...selectedProductsList, {
      name: product.name,
      id: product.id,
      price: product.price,
      quantity: 1
    }]);
  }

  useEffect(() => {
    if (selectedStore) {
      const filtered = dataAllProducts?.allProducts?.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setSearchResult(filtered);
    }
  }, [searchQuery, dataAllProducts, selectedStore]);

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 overflow-y-auto bg-white border h-[80vh] rounded-md sm:w-4/6 md:w-3/6'>
        {initialLoading || loading ?
          <LoadingWidget />
          :
          <>
            <div className='flex justify-end py-2 text-gray-600'>
              <div onClick={() => setAddProductPopup(false)} className='cursor-pointer'>
                <MdClose size={20} />
              </div>
            </div>

            <div className='my-2'>
              <div className="relative flex items-center w-full h-12 my-2 overflow-hidden bg-white border rounded-lg">
                <div className="grid w-12 h-full text-gray-300 place-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />
              </div>

              <div className='pt-2 overflow-x-scroll'>
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                        {t('name')}
                      </th>

                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                        {t('quantity')}
                      </th>

                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                        {t('price')}
                      </th>

                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                        {t('option')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {searchQuery === "" ?
                      Products?.allProducts.map((product: any) => (
                        <tr key={product.id}>
                          <td className="px-4 py-4 text-sm">
                            <p className="text-gray-500">{product.name}</p>
                          </td>
                          <td className="px-4 py-4 text-sm">
                            <p className="text-gray-500">{product.quantity === 99999 ? "unlimited" : product.quantity}</p>
                          </td>

                          <td className="px-4 py-4 text-sm">
                            ${product.price}
                          </td>
                          <td className="px-4 py-4 text-sm">
                            <div className='flex justify-center'>
                              <button onClick={() => { addProduct(product) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                                <AiOutlineAppstoreAdd size={20} />

                                <span className="mx-2">
                                  {t('select')}
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                      )
                      :
                      searchResult?.map((product: any) => (
                        <tr key={product.id}>
                          <td className="px-4 py-4 text-sm">
                            <p className="text-gray-500">{product.name}</p>
                          </td>
                          <td className="px-4 py-4 text-sm">
                            <p className="text-gray-500">{product.quantity === 99999 ? "unlimited" : product.quantity}</p>
                          </td>

                          <td className="px-4 py-4 text-sm">
                            ${product.price}
                          </td>
                          <td className="px-4 py-4 text-sm">
                            <div className='flex justify-center'>
                              <button onClick={() => { addProduct(product) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                                <AiOutlineAppstoreAdd size={20} />

                                <span className="mx-2">
                                  {t('select')}
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>

                {dataAllProducts?.allProducts.length === 0 &&
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('noProducts')}</span>
                  </div>
                }

                {searchQuery !== "" &&
                  searchResult !== undefined &&
                  searchResult.length === 0 &&
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('notMatch')}</span>
                  </div>
                }
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default SelectProductPopup