import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react'
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';

interface Props {
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
  id: string
  direction: string
}

const ProductsDropDown = ({ direction, setSelectedIdForDelete, id, setDeleteConfirmPopup }: Props) => {
  const [isDropDownOpen, setDropDownStatus] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setDropDownStatus(false);
      }
    })
  }, [])

  return (
    <div ref={ref}>
      <button onClick={() => { setDropDownStatus(!isDropDownOpen); }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
        </svg>
      </button>
      <div className={`${!isDropDownOpen && "hidden"} absolute ${direction === "ltr" ? 'right-3' : 'left-3'} z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl border`}>
        <Link to={`/products/edit/${id}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
          <BiEdit size={20} />

          <span className="mx-2 font-semibold">
            {t('edit')}
          </span>
        </Link>

        <div onClick={() => { setSelectedIdForDelete(id); setDeleteConfirmPopup(true) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
          <RiDeleteBin6Line size={20} className='text-red-600' />

          <span className="mx-2 font-semibold">{t('delete')}</span>
        </div>
      </div>
    </div>
  )
}

export default ProductsDropDown