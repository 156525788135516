import { gql, useLazyQuery } from '@apollo/client';
import { FetchDomains } from '../../types';

export const ALL_Domains = gql`
query AllDomains {
  allDomains {
    domain
    available
    currency
    definitive
    period
    price
    status
    loginInformation {
      url
      username
      password
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        permissions {
          id
          name
          permissions
          createdAt
          updatedAt
          deletedAt
        }
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
      
    }
  }
}
`;

export const useAllDomains = () => {
  return useLazyQuery<FetchDomains>(ALL_Domains);
}