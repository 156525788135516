import React from 'react'
import ClientsDropDown from '../DropDowns/ClientsDropDown'
import { ClientsProps, FetchClients, checkedItems } from '../../types'
import { t } from 'i18next'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  searchQuery: string,
  direction: string,
  searchResult: ClientsProps[] | undefined,
  allClients?: FetchClients
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  startingIndex: number
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const ClientsTable = ({ checkedItems, setCheckedItems, direction, searchQuery, searchResult, allClients, setDeleteConfirmPopup, startingIndex, setSelectedIdForDelete }: TableProps) => {

  const handleCheckAll = () => {
    if (allClients?.clientsAllStores.length !== checkedItems.length) {
      allClients !== undefined && setCheckedItems(allClients?.clientsAllStores.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-4 text-sm font-normal text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allClients?.clientsAllStores.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('phone')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('email')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('city')}
          </th>

          <th scope="col" className="px-10 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('dateOfBirth')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('gander')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {/* Show the Search result only when search keyword is set */}
        {searchQuery === "" ?
          allClients?.clientsAllStores?.slice(startingIndex, startingIndex + 10).map(client => {
            return (
              <tr key={client.id}>
                <td className="px-3 py-4 text-sm font-medium">
                  <div className='flex'>
                    <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} onChange={() => handleCheck(client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                    <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                </td>

                <td className="px-3 py-3 text-sm">
                  <h2 className="text-gray-800">{client.name}</h2>
                </td>

                <td className="px-3 py-4 text-sm">
                  <p className="text-gray-500">{client.phone}</p>
                </td>

                <td className="px-3 py-4 text-sm">
                  <p className="text-gray-500">{client.email}</p>
                </td>

                <td className="px-3 py-3 text-sm whitespace-nowrap">
                  <p className="text-gray-500">{client.address === null ? "N/A" : client.address}</p>
                </td>

                <td className="px-10 py-3 text-sm whitespace-nowrap">
                  <p className="text-gray-500">{client.dateBirth === null ? "N/A" : client.dateBirth}</p>
                </td>

                <td className="px-3 py-4 text-sm whitespace-nowrap">
                  <p className="text-gray-500">{client.gander === null ? "N/A" : t(client.gander)}</p>
                </td>

                <td className="px-3 py-4 text-sm">
                  <ClientsDropDown direction={direction} id={client.id} setDeleteConfirmPopup={setDeleteConfirmPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
                </td>
              </tr>
            )
          })
          :
          searchResult?.map(client => (
            <tr key={client.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} onChange={() => handleCheck(client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-3 py-3 text-sm">
                <h2 className="text-gray-800">{client.name}</h2>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.phone}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.email}</p>
              </td>

              <td className="px-3 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.address === null ? "N/A" : client.address}</p>
              </td>

              <td className="px-10 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.dateBirth === null ? "N/A" : client.dateBirth}</p>
              </td>

              <td className="px-3 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.gander === null ? "N/A" : t(client.gander)}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <ClientsDropDown direction={direction} id={client.id} setDeleteConfirmPopup={setDeleteConfirmPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default ClientsTable