import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClientsProps, FetchClients, checkedItems } from '../../types'
import { useAllClients } from '../../graphql'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import ClientsTable from '../../components/Tables/ClientsTable'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import { t } from 'i18next'

const Clients = ({ direction }: { direction: string }) => {

  // The state that contains Fetched Client, i didn't use dataAllClients from useAllClients beacuse i couldn't modify it to contain filtered result
  const [Clients, setClients] = useState<FetchClients>();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [deleteConfirmPopup, setDeleteConfPopup] = useState(false);

  const [allClients, { loading, data: dataAllClients }] = useAllClients();

  useEffect(() => {
    (async () => {
      await allClients()

      setInitialLoading(false)
    })()

  }, []);

  useEffect(() => {
    dataAllClients !== undefined && setClients(dataAllClients)
  }, [dataAllClients]);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0);

  const [showFilterOptions, setShowFilterOptions] = useState(false);

  // The Drop Down of the filter options
  const [CityFilterDropDown, setCityFilterDropDown] = useState(false);
  const [CityFilterQuery, setCityFilterQuery] = useState("");

  const [GanderDropDown, setGanderDropDown] = useState(false);
  const [GanderFilterQuery, setGanderFilterQuery] = useState("");

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<ClientsProps[] | undefined>([]);

  useEffect(() => {
    const filtered = dataAllClients?.clientsAllStores.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResult(filtered);
  }, [searchQuery, dataAllClients]);

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  // City Filter
  // Collect cities
  const uniqueCitiesSet = new Set(dataAllClients?.clientsAllStores.map((user) => user.address));
  const uniqueCities = Array.from(uniqueCitiesSet);

  useEffect(() => {
    if (CityFilterQuery !== "" && dataAllClients !== undefined) {
      const filtered = dataAllClients?.clientsAllStores.filter((item) =>
        item?.address?.toLowerCase().includes(CityFilterQuery.toLowerCase())
      );

      setClients({ clientsAllStores: filtered });
    }

  }, [CityFilterQuery, dataAllClients])

  useEffect(() => {
    if (GanderFilterQuery !== "" && dataAllClients !== undefined) {
      const filtered = dataAllClients?.clientsAllStores.filter((selectedCity) =>
        selectedCity.gander === GanderFilterQuery
      );

      setClients({ clientsAllStores: filtered });
    }
  }, [GanderFilterQuery, dataAllClients]);

  const clearFilters = () => {
    dataAllClients !== undefined && setClients(dataAllClients)
    setCityFilterQuery("");
    setGanderFilterQuery("");
  }

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfirmPopup && <DeleteConfirmPopup checkedItems={checkedItems} selectedIdForDelete={selectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('clients')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='my-4 md:flex md:justify-between'>
              <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
                <div className="grid w-12 h-full text-gray-300 place-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none peer" type="text" id="search" placeholder={t('searchSomething')} />
              </div>

              <div className='gap-3 sm:flex'>
                <button onClick={() => { setShowFilterOptions(!showFilterOptions) }} className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583-5.71 5.71z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('filter')}
                  </span>
                </button>

                <Link to="/clients/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('Add Client')}
                  </span>
                </Link>
              </div>
            </div>

            {showFilterOptions &&
              <div>
                <div className='grid grid-cols-2 gap-2'>
                  <div className="relative inline-block">
                    <button onClick={() => { setCityFilterDropDown(!CityFilterDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                      <span className="mx-1">
                        {CityFilterQuery !== "" ? CityFilterQuery : t("country")}
                      </span>
                      <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                      </svg>
                    </button>

                    {CityFilterDropDown &&
                      <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                        {uniqueCities.map((city, index) => (
                          <span key={index} onClick={() => { setCityFilterQuery(city); setCityFilterDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">{city}</span>
                        ))}
                      </div>
                    }
                  </div>

                  <div className="relative inline-block">
                    <button onClick={() => { setGanderDropDown(!GanderDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                      <span className="mx-1">
                        {GanderFilterQuery !== "" ? t(GanderFilterQuery) : t("gander")}
                      </span>
                      <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                      </svg>
                    </button>

                    {GanderDropDown &&
                      <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                        <span onClick={() => { setGanderFilterQuery("male"); setGanderDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          {t('male')}
                        </span>
                        <span onClick={() => { setGanderFilterQuery("female"); setGanderDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          {t('female')}
                        </span>
                      </div>
                    }
                  </div>
                </div>

                <div className='flex justify-end'>
                  <button onClick={clearFilters} className='px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                    {t('clear')}
                  </button>
                </div>
              </div>
            }
          </div>

          <div className='pt-2 overflow-x-scroll'>
            <ClientsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} searchQuery={searchQuery} searchResult={searchResult} allClients={Clients} startingIndex={startingIndex} setFailMsg={setFailMsg} setDeleteConfirmPopup={setDeleteConfPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
          </div>

          {dataAllClients?.clientsAllStores.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('No Clients to show')}</span>
            </div>
          }

          {searchQuery !== "" &&
            searchResult !== undefined &&
            searchResult.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span className=''>{t('of')} {dataAllClients?.clientsAllStores.length} {t('records')}</span>
          </div>

          <div dir={"ltr"} className='flex items-center justify-between'>
            {Clients !== undefined &&
              startingIndex !== 0 &&
              <div className={`gap-1.5 text-gray-500 py-2`}>
                <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <IoArrowBackSharp size={18} />
                  <span className="mx-2">
                    {t('prev')}
                  </span>
                </button>
              </div>
            }

            {Clients !== undefined &&
              startingIndex + 10 < Clients?.clientsAllStores.length &&
              <div className={`ml-auto gap-1.5 text-gray-500 py-2`}>
                <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <IoArrowForward size={18} />
                  <span className="mx-2">
                    {t('next')}
                  </span>
                </button>
              </div>
            }
          </div>
        </div>
      }
    </section>
  )
}

export default Clients