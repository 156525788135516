import { gql, useLazyQuery } from "@apollo/client"
import { FetchUsers } from "../../types";

export const ALL_USERS = gql`
query UsersAllStores {
  usersAllStores {
    id
    profilePicture
    name
    email
    phone
    role
    store {
      id
      name
      link
      logo
      icon
      description
      address
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    activation
    emailVerify
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllUsers = () => {
  return useLazyQuery<FetchUsers>(ALL_USERS);
}