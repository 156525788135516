import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useAllStores } from '../../graphql';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { StoresProps } from '../../types';
import { t } from 'i18next';

interface Props {
  setSelectStorePopup: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedStore: React.Dispatch<React.SetStateAction<StoresProps | null>>
}

const SelectStorePopup = ({ setSelectStorePopup, setSelectedStore }: Props) => {

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allStores, { loading, data: dataAllStores }] = useAllStores();

  useEffect(() => {
    (async () => {
      await allStores();

      setInitialLoading(false)
    })()

  }, [allStores]);

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 overflow-y-auto bg-white border sm:w-4/6 md:w-3/6 h-[80vh]'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setSelectStorePopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        {initialLoading || loading ?
          <LoadingWidget />
          :
          <div className='overflow-x-scroll'>
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('name')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('products')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('clients')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('orders')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('address')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('option')}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {dataAllStores?.allStores.map((store: any) => (
                  <tr key={store.id}>
                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      {store.name}
                    </td>
                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      {store.numberProducts}
                    </td>
                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      {store.numberClients}
                    </td>
                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      {store.numberOrders}
                    </td>
                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      {store.address === null ? "N/A" : store.address}
                    </td>

                    <td className='px-4 py-3 text-sm whitespace-nowrap'>
                      <button onClick={() => { setSelectedStore(store); setSelectStorePopup(false) }} type='button' className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 md:my-0 hover:bg-primaryHover'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                          <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                        </svg>

                        <span className="mx-2">
                          {t('select')}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default SelectStorePopup