import React from 'react'
import ProductsDropDown from '../DropDowns/ProductsDropDown'
import { FetchProducts, ProductProps, checkedItems } from '../../types'
import { t } from 'i18next'

interface Props {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allProducts?: FetchProducts
  searchQuery: string
  direction: string
  searchResult: ProductProps[] | undefined
  startingIndex: number
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const ProductsTable = ({ checkedItems, setCheckedItems, direction, allProducts, searchQuery, searchResult, startingIndex, setSelectedIdForDelete, setDeleteConfirmPopup }: Props) => {

  const handleCheckAll = () => {
    if (allProducts?.productsAllStores.length !== checkedItems.length) {
      allProducts !== undefined && setCheckedItems(allProducts.productsAllStores.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200 ">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
            <div className='flex'>
              <input type="checkbox" checked={allProducts?.productsAllStores.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('product')}
          </th>

          <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('quantity')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('price')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {searchQuery !== "" ?
          searchResult?.map(product => (
            <tr key={product.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === product.id)} onChange={() => handleCheck(product.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className="px-4 py-4 text-sm">
                <img className='w-14 h-14' src={`https://store-api.qafilaty.com/images/${product.imagesProduct[0]?.name}`} alt="" />
              </td>
              <td className="px-12 py-4 text-sm">
                <p className="text-gray-500">{product.name}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{product.quantity === 99999 ? "unlimited" : product.quantity}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                ${product.price}
              </td>

              <td className="px-4 py-4 text-sm">
                <ProductsDropDown direction={direction} id={product.id} setDeleteConfirmPopup={setDeleteConfirmPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
              </td>
            </tr>
          ))
          :
          allProducts?.productsAllStores.slice(startingIndex, startingIndex + 10).map(product => (
            <tr key={product.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === product.id)} onChange={() => handleCheck(product.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className="px-4 py-4 text-sm">
                <img className='w-14 h-14' src={`https://store-api.qafilaty.com/images/${product.imagesProduct[0]?.name}`} alt="" />
              </td>
              <td className="px-12 py-4 text-sm">
                <p className="text-gray-500">{product.name}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{product.quantity === 99999 ? "unlimited" : product.quantity}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                ${product.price}
              </td>

              <td className="px-4 py-4 text-sm">
                <ProductsDropDown direction={direction} id={product.id} setDeleteConfirmPopup={setDeleteConfirmPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default ProductsTable