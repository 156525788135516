import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'

const Settings = () => {
  return (
    <section className='my-4 mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('settings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='my-4'>
        <div>
          <h3 className='text-lg text-gray-700'>{t('accountSettings')}</h3>
        </div>
        <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>
          <div>
            <Link to='/settings/account_settings'>
              <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                <div className="p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path>
                  </svg>
                </div>
                <div className='flex flex-col items-center justify-between h-full'>
                  <h2 className="mb-1 text-xl text-center title-font">{t('accountSettings')}</h2>
                  <p className="pt-2 text-sm text-center text-gray-400">{t('profileSettings')}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Settings