import { gql, useLazyQuery } from "@apollo/client"
import { FetchStore } from "../../types";

export const Store = gql`
query Store($storeId: ID) {
  store(id: $storeId) {
    id
    name
    link
    logo
    icon
    description
    address
    numberProducts
    numberOrders
    numberClients
    admin {
      id
      profilePicture
      name
      email
      phone
      role
      activation
      emailVerify
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useStore = () => {
  return useLazyQuery<FetchStore>(Store);
}