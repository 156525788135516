import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { client } from "./lib/graphql";
import { ProtectedPage } from './lib/helpers/_auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ProtectedPage client={client}>
        <App />
      </ProtectedPage>
    </BrowserRouter>
  </ApolloProvider>
);