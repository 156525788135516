import { gql, useLazyQuery } from "@apollo/client"
import { FetchProducts } from "../../types";

export const ALL_PRODUCTS = gql`
query ProductsAllStores {
  productsAllStores {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    brand {
      id
      name
      image
      classFirstLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      store {
        id
      }
      createdAt
      updatedAt
      deletedAt
    }
    classThirdLevel {
      id
      name
      classSecondLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    productOptions {
      id
      name
      subOptions {
        id
        name
        price
        quantity
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllProducts = () => {
  return useLazyQuery<FetchProducts>(ALL_PRODUCTS);
}