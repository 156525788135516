import React, { useEffect, useState } from 'react';
import './styles/index.css';
import MainLayout from './components/mainLayout';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import translations_en from './locales/en.json';
import translations_ar from './locales/ar.json';
import SocketClient from './lib/socket';
import useStore from './store/useStore';

const defaultLanguage = window.localStorage.getItem('defaultLanguage');

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translations_en,
    },
    ar: {
      translation: translations_ar,
    },
  },
  lng: defaultLanguage || 'en', // Default language
  fallbackLng: defaultLanguage || 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const storeData = useStore((state: any) => state.userData);

  const [direction, setDirection] = useState(i18n.dir());

  useEffect(() => {
    const handleLanguageChange = () => {
      setDirection(i18n.dir());
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    let socketIo: any = null
    if (storeData && Object.keys(storeData).length > 0 && "id" in storeData) {
      socketIo = new SocketClient({
        idUser: storeData.id,
      });

      socketIo.connect();
    }

    return () => {
      if (socketIo != null) {
        socketIo.disconnect();
      }
    };
  }, [storeData]);

  return (
    <I18nextProvider i18n={i18n}>
      <div dir={direction} className="App">
        <MainLayout direction={direction} />
      </div>
    </I18nextProvider>
  );
}

export default App;
