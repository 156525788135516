import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai'
import BlogDropDown from '../../components/DropDowns/BlogDropDown'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { useAllBlogs } from '../../graphql'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import FailAlert from '../../components/Alerts/FailAlert'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import { t } from 'i18next'

const ContentManagement = ({ direction }: { direction: string }) => {

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [deleteConfirmPopup, setDeleteConfPopup] = useState(false);

  const [allBlogs, { loading, data: dataAllBlogs }] = useAllBlogs();

  useEffect(() => {
    (async () => {
      await allBlogs();

      setInitialLoading(false)
    })()

  }, []);

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfirmPopup && <DeleteConfirmPopup selectedIdForDelete={selectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('contentManagement')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div>
          <div className='p-2 mt-3 bg-white rounded'>
            <div className='flex justify-end'>
              <Link to="/content_management/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('add blog')}
                </span>
              </Link>
            </div>
          </div>

          {dataAllBlogs?.allBlogs.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('No Blogs to show')}</span>
            </div>
          }

          <div className='py-5'>
            <div className="grid grid-cols-1 gap-10 auto-rows-fr md:grid-cols-2 xl:grid-cols-3">
              {dataAllBlogs?.allBlogs.map((blog) => (
                <div key={blog.id}>
                  <div className="h-full overflow-hidden rounded-lg shadow-md border-opacity-60">
                    <img className="object-cover object-center w-full h-60 lg:h-60 md:h-40" src={`https://store-api.qafilaty.com/images/${blog.imagesBlog[0]}`} alt="blog" />

                    <div className="relative px-6 bg-white">
                      <div className='flex items-center justify-between mb-2 pt-7'>
                        <span className='text-sm font-bold text-gray-400'>
                          {new Date(blog.createdAt).toLocaleString(direction === "ltr" ? "en" : "ar", { year: 'numeric', month: 'short', day: '2-digit' })}
                        </span>

                        <div className='cursor-pointer'>
                          <BlogDropDown direction={direction} id={blog.id} setDeleteConfirmPopup={setDeleteConfPopup} setSelectedIdForDelete={setSelectedIdForDelete} />
                        </div>
                      </div>

                      <div className='flex flex-col justify-between'>
                        <div>
                          <h1 className="h-20 pt-2 my-3 text-2xl font-bold text-gray-700 truncate-mine">
                            {blog.title}
                          </h1>
                          <p className="my-3 text-sm text-gray-500 truncate">
                            {blog.shortDescription}
                          </p>
                        </div>

                        <div>
                          <Link to={`http://store-landing.qafilaty.com/blog/${blog.id}`} className="inline-flex items-center gap-3 mt-3 font-bold pb-7 text-primary">
                            {t('Read More')}
                            {direction === "ltr" ? <AiOutlineArrowRight /> : <AiOutlineArrowLeft />}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </section>
  )
}

export default ContentManagement