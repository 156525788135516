import { gql, useLazyQuery } from "@apollo/client"
import { FetchClassFirstLevel } from "../../types";

export const ClassFirstLevel = gql`
query ClassFirstLevel($classFirstLevelId: ID) {
  classFirstLevel(id: $classFirstLevelId) {
    id
    name
    createdAt
    updatedAt
    deletedAt
    classSecondLevel {
      classThirdLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
        classSecondLevel {
          id
        }
      }
      createdAt
      deletedAt
      id
      name
      updatedAt
      classFirstLevel {
        id
      }
    }
  }
}
`

export const useClassFirstLevel = () => {
  return useLazyQuery<FetchClassFirstLevel>(ClassFirstLevel);
}