import React, { useEffect } from 'react'
import {
  AccountSettings,
  AddBlog,
  AddClient,
  AddOrders,
  AddProduct,
  AddStore,
  AddSubscription,
  AddUser,
  Clients,
  ContentManagement,
  DomainRequests,
  EditBlog,
  EditClient,
  EditProduct,
  EditStore,
  EditSubscription,
  EditUser,
  Home,
  Orders,
  Products,
  Settings,
  Stores,
  SubscriptionManagement,
  Users
} from '../pages'
import { Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import Login from '../pages/Auth/Login'
import useStore from '../store/useStore'

const MainLayout = ({ direction }: { direction: string }) => {

  const userData = useStore((state: any) => state.userData);

  useEffect(() => {
    userData.store !== undefined &&
      console.log(userData);
  }, [userData])

  const routes = [
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/stores',
      element: <Stores direction={direction} />
    },
    {
      path: '/stores/create',
      element: <AddStore />
    },
    {
      path: '/stores/edit/:id',
      element: <EditStore />
    },
    {
      path: '/users',
      element: <Users direction={direction} />
    },
    {
      path: '/users/create',
      element: <AddUser />
    },
    {
      path: '/users/edit/:id',
      element: <EditUser />
    },
    {
      path: '/clients',
      element: <Clients direction={direction} />
    },
    {
      path: '/clients/create',
      element: <AddClient />
    },
    {
      path: '/clients/edit/:id',
      element: <EditClient />
    },
    {
      path: '/products',
      element: <Products direction={direction} />
    },
    {
      path: '/products/create',
      element: <AddProduct direction={direction} />
    },
    {
      path: '/products/edit/:id',
      element: <EditProduct direction={direction} />
    },
    {
      path: '/orders',
      element: <Orders direction={direction} />
    },
    {
      path: '/orders/create',
      element: <AddOrders direction={direction} />
    },
    {
      path: '/content_management',
      element: <ContentManagement direction={direction} />
    },
    {
      path: '/content_management/create',
      element: <AddBlog />
    },
    {
      path: '/content_management/edit/:id',
      element: <EditBlog />
    },
    {
      path: '/subscription_management',
      element: <SubscriptionManagement direction={direction} />
    },
    {
      path: '/subscription_management/create',
      element: <AddSubscription />
    },
    {
      path: '/subscription_management/edit/:id',
      element: <EditSubscription />
    },
    {
      path: '/settings',
      element: <Settings />
    },
    {
      path: '/domain_requests',
      element: <DomainRequests />
    },
    {
      path: '/settings/account_settings',
      element: <AccountSettings />
    },
  ]

  return (
    <main>
      <Routes>
        <Route index path="/" element={<Login direction={direction} />} />

        <Route element={<Layout direction={direction} />}>
          {routes.map((page, index) => (
            <Route key={index} index={index === 0 && true} path={page.path} element={page.element} />
          ))}
        </Route>
      </Routes>
    </main>
  )
}

export default MainLayout