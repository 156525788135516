import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from './SideBar/SideBar';
import MainHeader from './Header/Header';

interface Props {
  direction: string
}

const Layout = ({ direction }: Props) => {
  const [menu, setMenu] = useState(false);

  return (
    <main>
      <div className='flex'>
        <div className='sidebar'>
          <SideBar menu={menu} setMenu={setMenu} />
        </div>

        <div className={`w-full min-h-screen bg-gray-100 ${direction === "ltr" ? "md:pl-64" : "md:pr-64"}`} id="content">
          <div>
            <MainHeader setMenu={setMenu} direction={direction} />
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Layout