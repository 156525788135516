import React, { useEffect, useState } from 'react'
import StoresTable from '../../components/Tables/StoresTable'
import { Link } from 'react-router-dom';
import { FetchStores, checkedItems } from '../../types';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import AddAdminPopup from './AddAdminPopup';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import { useAllStores } from '../../graphql';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';

const Stores = ({ direction }: { direction: string }) => {

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // The state that contains Fetched Stores, i didn't use dataAllStores from useAllStores beacuse i couldn't modify it to contain filtered result
  const [Stores, setStores] = useState<FetchStores>();

  const [deleteConfirmPopup, setDeleteConfPopup] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allStores, { loading, data: dataAllStores }] = useAllStores();

  useEffect(() => {
    (async () => {
      await allStores();

      setInitialLoading(false)
    })()

  }, [allStores]);

  useEffect(() => {
    dataAllStores !== undefined && setStores(dataAllStores)
  }, [dataAllStores]);

  // This state is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0);

  const [addAdminPopup, setAddAdminPopup] = useState(false);

  // This state contain the store id of the store that will be add an admin to it, it could be also use for delete
  const [selectedStoreId, setSelectedStoreId] = useState("");

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<any>([]);

  useEffect(() => {
    const filtered = dataAllStores?.allStores.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResult(filtered);
  }, [searchQuery, dataAllStores]);

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {addAdminPopup && <AddAdminPopup setAddAdminPopup={setAddAdminPopup} selectedStoreId={selectedStoreId} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {deleteConfirmPopup && <DeleteConfirmPopup checkedItems={checkedItems} selectedIdForDelete={selectedStoreId} setDeleteConfPopup={setDeleteConfPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('stores')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div className='my-4 md:flex md:justify-between'>
            <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
              <div className="grid w-12 h-full text-gray-300 place-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>

              <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none peer" type="text" id="search" placeholder={t('searchSomething')} />
            </div>

            <Link to="/stores/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
              </svg>

              <span className="mx-2">
                {t('Add Store')}
              </span>
            </Link>
          </div>

          <div className='h-full pt-2 overflow-x-scroll'>
            <StoresTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} allStores={Stores} searchQuery={searchQuery} startingIndex={startingIndex} searchResult={searchResult} setAddAdminPopup={setAddAdminPopup} setSelectedStoreId={setSelectedStoreId} setDeleteConfirmPopup={setDeleteConfPopup} />
          </div>

          {searchQuery !== "" &&
            searchResult !== undefined &&
            searchResult.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }

          {Stores?.allStores.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('No Stores to show')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span className=''>{t('of')} {Stores?.allStores.length} {t('records')}</span>
          </div>

          <div dir={"ltr"} className='flex items-center justify-between'>
            {Stores !== undefined &&
              startingIndex !== 0 &&
              <div className={`gap-1.5 text-gray-500 py-2`}>
                <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <IoArrowBackSharp size={18} />
                  <span className="mx-2">
                    {t('prev')}
                  </span>
                </button>
              </div>
            }

            {Stores !== undefined &&
              startingIndex + 10 < Stores?.allStores.length &&
              <div className={`ml-auto gap-1.5 text-gray-500 py-2`}>
                <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <IoArrowForward size={18} />
                  <span className="mx-2">
                    {t('next')}
                  </span>
                </button>
              </div>
            }
          </div>
        </div>
      }
    </section>
  )
}

export default Stores