import { gql, useLazyQuery } from "@apollo/client"
import { FetchSubscription } from "../../types";

export const SUBSCRIPTION = gql`
query Subscription($subscriptionId: ID) {
  subscription(id: $subscriptionId) {
    id
    planName
    price
    discount
    features
    published
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useSubscription = () => {
  return useLazyQuery<FetchSubscription>(SUBSCRIPTION);
}