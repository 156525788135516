import { gql, useLazyQuery } from "@apollo/client"
import { FetchClientsStore } from "../../types";

export const ALL_ClIENTS_STORE = gql`
query AllClient($idStore: ID) {
  allClient(idStore: $idStore) {
    id
    name
    email
    phone
    dateBirth
    address
    gander
    note
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllClientsStore = () => {
  return useLazyQuery<FetchClientsStore>(ALL_ClIENTS_STORE);
}