import { gql } from "@apollo/client"

export const CreateUser = gql`
mutation CreateUser($content: contentUser!) {
  createUser(content: $content) {
    id
    profilePicture
    name
    email
    phone
    role
    activation
    emailVerify
    createdAt
    updatedAt
    deletedAt
  }
}
`