import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsCheckAll } from 'react-icons/bs'
import SubscriptionDropDown from '../../components/DropDowns/SubscriptionDropDown'
import { useAllSubscriptions, useAllSubscriptionsStores } from '../../graphql'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5'

const SubscriptionManagement = ({ direction }: { direction: string }) => {

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [deleteConfirmPopup, setDeleteConfPopup] = useState(false);
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);

  const [allSubscriptions, { loading, data: dataAllSubscriptions }] = useAllSubscriptions();
  const [allSubscriptionsStores, { loading: dataSubscribersLoading, data: dataAllSubscriptionsStores }] = useAllSubscriptionsStores();

  useEffect(() => {
    (async () => {
      await allSubscriptions();
      await allSubscriptionsStores();

      setInitialLoading(false)
    })()
  }, []);

  // This state is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0);

  const [totalLength, setTotalLength] = useState(0);

  useEffect(() => {
    dataAllSubscriptionsStores?.allSubscribersStores.map(sub => (
      setTotalLength(totalLength + sub.listSubscription.length)
    ))
  }, [dataAllSubscriptionsStores])

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {deleteConfirmPopup && <DeleteConfirmPopup selectedIdForDelete={selectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t("subscriptionManagement")}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded'>
        <div className='flex justify-end'>
          <Link to="/subscription_management/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
              <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
            </svg>

            <span className="mx-2">
              {t('add Plan')}
            </span>
          </Link>
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        dataAllSubscriptions?.allSubscriptions.length === 0 ?
          <div className='flex items-center justify-center w-full py-2 my-2 font-semibold text-red-600 bg-red-100'>
            <p>{t('No Plans to show')}</p>
          </div>
          :
          <div className='grid grid-cols-3 gap-3 my-4'>
            {dataAllSubscriptions?.allSubscriptions.map(plane => (
              <div key={plane.id} className='p-4 bg-white rounded-md shadow-md'>
                <div className='relative'>
                  <div className='flex justify-between'>
                    <div>
                      <h3 className='text-3xl font-bold text-gray-700'>{plane.planName}</h3>
                      <p className='font-bold text-primary'>{plane.price === null || plane.price === 0 ? t('free') : "$" + plane.price}/{t('mo')}</p>
                    </div>
                    <div>
                      <SubscriptionDropDown direction={direction} id={plane.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfirmPopup={setDeleteConfPopup} />
                    </div>
                  </div>
                  <div className="w-full h-px my-5 bg-gray-300"></div>
                </div>
                <div>
                  {plane.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-3 mb-2">
                      <div className="text-primary">
                        <BsCheckAll size={20} />
                      </div>
                      <p className='text-sm'>{feature}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))
            }
          </div>
      }

      <section className='mt-2'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('Subscribers')}</h3>
        </div>
        {initialLoading || dataSubscribersLoading ?
          <LoadingWidget />
          :
          <div className='px-3 py-4 mt-3 bg-white rounded-md'>

            {dataAllSubscriptionsStores?.allSubscribersStores.length === 0 ?
              <div className='flex items-center justify-center w-full py-2 my-2 font-semibold text-red-600 bg-red-100'>
                <p>{t('No Subscribers to show')}</p>
              </div>
              :
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('store')}
                    </th>

                    <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('subscription')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('price')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('startDate')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('endDate')}
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {dataAllSubscriptionsStores?.allSubscribersStores.map(subscription => (
                    subscription.listSubscription.map(subscription_i => (
                      <tr key={subscription.id}>
                        <td className="px-4 py-4 text-sm font-medium">
                          {subscription.name}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium">
                          {subscription_i.subscription.planName}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium">
                          ${subscription_i.price}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium">
                          {new Date(subscription_i.startDate * 1000).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium">
                          {new Date(subscription_i.endDate * 1000).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            }

            <div dir={"ltr"} className='flex items-center justify-between'>
              {dataAllSubscriptionsStores !== undefined &&
                startingIndex !== 0 &&
                <div className={`gap-1.5 text-gray-500 py-2`}>
                  <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <IoArrowBackSharp size={18} />
                    <span className="mx-2">
                      {t('prev')}
                    </span>
                  </button>
                </div>
              }

              {dataAllSubscriptionsStores !== undefined &&
                startingIndex + 10 < totalLength &&
                <div className={`ml-auto gap-1.5 text-gray-500 py-2`}>
                  <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <IoArrowForward size={18} />
                    <span className="mx-2">
                      {t('next')}
                    </span>
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </section>
    </section>
  )
}

export default SubscriptionManagement