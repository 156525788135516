import { gql } from "@apollo/client"

export const CreateSubscription = gql`
mutation CreateSubscription($content: contentSubscription!) {
  createSubscription(content: $content) {
    id
    planName
    price
    discount
    features
    createdAt
    updatedAt
    deletedAt
  }
}
`