import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu'
import { RiNotification3Line } from 'react-icons/ri'
import { IoLanguageSharp } from 'react-icons/io5';
import { BiSolidUser, BiUser } from 'react-icons/bi';
import { useMutation } from '@apollo/client';
import { LogOut } from '../../graphql';
import useStore from '../../store/useStore';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

interface Props {
  setMenu: React.Dispatch<React.SetStateAction<boolean>>,
  direction: string
}

const Header = ({ setMenu, direction }: Props) => {

  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [profileDropDown, setProfileDropDown] = useState(false);

  const [langDropDown, setLangDropDown] = useState(false);
  const notificationRef = useRef<any>();
  const profileRef = useRef<any>();
  const langRef = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (notificationRef.current || profileRef.current) {
        if (notificationRef.current.contains(e.target as Node) || profileRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setNotificationDropDown(false);
        setProfileDropDown(false);
      }
    })
  }, [])

  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    setProfileDropDown(false);
    i18n.changeLanguage(lng);
    localStorage.setItem('defaultLanguage', lng);
    setLangDropDown(false);
  };

  const [logout] = useMutation(LogOut, {
    onError: (error) => {
      // @ts-ignore
      const code = error.message;
      console.error(code);

    },
    onCompleted: () => {
      useStore.setState({ isAuth: false, token: "" });
      useStore.setState({ userData: {} });
      //navigate(`/${params.storeLink}/login`);
      window.location.reload();
    }
  })

  const submitLogOut = async () => {
    await logout()
  }

  return (
    <header className="px-6 py-4 bg-white">
      <div className="flex items-center justify-between mx-auto">
        <div>
          <button className='md:hidden' onClick={() => { setMenu(true) }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="rgba(0, 0, 0, 1)">
              <path d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"></path>
            </svg>
          </button>
        </div>

        <div className="flex justify-end">
          <div className="relative z-20 flex items-center bg-transparent bg-white opacity-100">
            <div className="flex items-center gap-4 px-2.5 py-1 bg-gray-100 rounded-full">
              <div ref={langRef} className='flex items-center'>
                <button onClick={() => setLangDropDown(!langDropDown)} className="text-gray-700 duration-300 outline-none hover:text-primary focus:outline-none">
                  <IoLanguageSharp size={20} />
                </button>

                <div className={`${!langDropDown && "hidden"} absolute ${direction === "ltr" ? "-left-full" : "-right-full"} top-12 border z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-sm`}>
                  <div onClick={() => { changeLanguage("en"); setProfileDropDown(false) }} className="p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">

                    <span className="mx-1 font-semibold">{t('en')}</span>
                  </div>
                  <div onClick={() => { changeLanguage("ar"); setProfileDropDown(false) }} className="p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">

                    <span className="mx-1 font-semibold">{t('ar')}</span>
                  </div>
                </div>
              </div>

              <div className='flex items-center' ref={notificationRef}>
                <button onClick={() => { setProfileDropDown(false); setNotificationDropDown(!notificationDropDown); }} className="relative text-gray-700 duration-300 outline-none hover:text-primary focus:outline-none">
                  <RiNotification3Line size={20} />
                </button>
                <div className={`${!notificationDropDown && "hidden"} absolute ${direction === "ltr" ? 'right-12' : 'left-12'} top-12 border z-20 w-[410px] py-2 mt-2 bg-white rounded-md shadow-xl`}>
                  <div>
                    <div className='px-4 pt-2 pb-3 text-gray-700 border-b'>
                      <h3 className='text-xl font-semibold'>{t('Notification')}</h3>
                    </div>

                    <div>
                      <div className='px-2 py-2'>
                        {t('No notification to show')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={profileRef}>
                <button onClick={() => { setNotificationDropDown(false); setProfileDropDown(!profileDropDown) }} className="flex items-center justify-center w-10 h-10 font-semibold text-white rounded-full bg-primary focus:outline-none">
                  <div className="relative flex items-center justify-center overflow-hidden text-lg bottom-px">
                    <BiSolidUser size={21} />
                  </div>
                </button>

                <div className={`${!profileDropDown && "hidden"} absolute ${direction === "ltr" ? "right-0" : "left-0"}  top-12 border z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl`}>
                  <Link to="/settings/profile" onClick={() => setProfileDropDown(!profileDropDown)} className="flex items-center gap-2 p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                    <BiUser size={21} />

                    <span className="mx-1 font-semibold">{t('profile')}</span>
                  </Link>

                  <div onClick={() => submitLogOut()} className="flex items-center gap-2 p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <LuLogOut size={21} />

                    <span className="mx-1 font-semibold">{t('logout')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header