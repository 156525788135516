import { gql, useLazyQuery } from "@apollo/client"
import { FetchBlog } from "../../types";

export const Blog = gql`
query Blog($blogId: ID) {
  blog(id: $blogId) {
    id
    title
    shortDescription
    description
    imagesBlog
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useBlog = () => {
  return useLazyQuery<FetchBlog>(Blog);
}