import { gql } from "@apollo/client"

export const CreateBlog = gql`
mutation CreateBlog($content: contentBlog!) {
  createBlog(content: $content) {
    id
    title
    shortDescription
    description
    imagesBlog
    createdAt
    updatedAt
    deletedAt
  }
}
`