import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ALL_BLOGS, UpdateBlog, useBlog } from '../../graphql';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { t } from 'i18next';

const formSchema = z.object({
  title: z.string().min(1, { message: t('title is required') }),
  shortDescription: z.string().min(1, { message: t('shortDescriptionRequire') }),
  images: z.array(z.any()).refine((value) => value.length > 0, {
    message: 'image is required.',
  }),
  content: z.string().min(1, { message: t('contentRequire') }),
})

type FormType = z.infer<typeof formSchema>

const EditBlog = () => {

  const { id } = useParams();

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState<any>("");

  // File input
  const [selectedPreviewImageUrl, setSelectedPreviewImageUrl] = useState<string[]>([]);
  const [oldImagesUrl, setOldImagesUrl] = useState<string[]>([]);
  const [imageToCrop, setImageToCrop] = useState<any>();
  const [cropPopup, setCropPopup] = useState(false);

  const cropperRef = useRef<any>();

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));

      setImageToCrop(urls[0]);
      setCropPopup(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {

      if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }

      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      setSelectedPreviewImageUrl([croppedImageUrl]);

      croppedCanvas.toBlob((blob: any) => {
        const file: any = new File([blob], "cropped-image.png", { type: "image/png" });
        // @ts-ignore
        setValue('images', [file]);
      });

      setCropPopup(false);
    }
  };

  const handlePopupClose = () => {
    setImageToCrop("");
    setCropPopup(false);
  };

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormType>({
    resolver: zodResolver(formSchema)
  })

  const [initialLoading, setInitialLoading] = useState(true);

  const [Blog, { loading, data: dataBlog }] = useBlog();

  useEffect(() => {
    (async () => {
      await Blog({
        variables: { blogId: id }
      })

      setInitialLoading(false)
    })()
  }, []);

  const [defaultContent, setDefaultContent] = useState("")

  useEffect(() => {
    if (dataBlog?.blog) {
      setValue("title", dataBlog.blog.title);
      setValue("shortDescription", dataBlog.blog.shortDescription);
      setOldImagesUrl(dataBlog.blog.imagesBlog);
      setValue("images", dataBlog.blog.imagesBlog);
      setValue("content", dataBlog.blog.description);
      setDefaultContent(dataBlog.blog.description);
    }
  }, [dataBlog])

  // Update
  const [updateBlog, { loading: updateLoading }] = useMutation(UpdateBlog, {
    onCompleted: () => {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    },
    onError: (error) => {
      console.error(error);
    },
    refetchQueries: [{
      query: ALL_BLOGS
    }]
  });

  const Submit: SubmitHandler<FormType> = async (data: FormType) => {
    updateBlog({
      variables: {
        updateBlogId: id,
        content: {
          description: data.content,
          ...(selectedPreviewImageUrl.length > 0 && { imagesBlog: data.images }),
          shortDescription: data.shortDescription,
          title: data.title
        }
      }
    })
  }

  useEffect(() => {
    const fieldCount = Object.keys(formSchema.shape).length;

    // if all the errors show up
    if (Object.keys(errors).length === fieldCount) {
      setFail(true);
      setFailMsg(t('formNonComplete'));
      return
    } else {
      if (errors.title) {
        setFail(true);
        setFailMsg(errors?.title?.message);
        return
      }

      if (errors.shortDescription) {
        setFail(true);
        setFailMsg(errors?.shortDescription?.message);
        return
      }

      if (errors.images) {
        if (errors.images.type === "invalid_type") {
          setFail(true);
          setFailMsg(t('imagesRequire'));
          return
        } else {
          setFail(true);
          setFailMsg(errors.images.message);
          return
        }
      }

      if (errors.content) {
        if (errors.content.type === "invalid_type") {
          setFail(true);
          setFailMsg(t('contentRequire'));
          return
        } else {
          setFail(true);
          setFailMsg(errors.content.message);
          return
        }
      }
    }

  }, [errors])

  return (
    <section className='my-4 mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {cropPopup && (
        <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-white/30 backdrop-blur-md'>
          <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-5/6'>
            <Cropper
              ref={cropperRef}
              src={imageToCrop}
              style={{ height: 400, width: '100%' }}
            />

            <div className='flex justify-end gap-2 mt-3'>
              <button onClick={handleCrop} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">{t('cropImage')}</button>
              <button onClick={handlePopupClose} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">{t('close')}</button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('Edit Blog')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <form onSubmit={handleSubmit(Submit)} className='relative p-2 mt-3 bg-white rounded-md'>
          {updateLoading &&
            <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 bg-white/70'>
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div>
          }

          <div className="mb-4">
            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900">{t('title')}</label>
            <input type="text" id="title" {...register("title", { required: true })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('title')} />
          </div>

          <div className="mb-4">
            <label htmlFor="shortDescription" className="block mb-2 text-sm font-medium text-gray-900">{t('short Description')}</label>
            <input type="text" id="shortDescription" {...register("shortDescription", { required: true })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('short Description')} />
          </div>

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">{t('Images')}</label>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    {t('selectPhoto')}
                  </p>
                </div>
                <input type="file" onChange={handleFileSelect} className="opacity-0" multiple={true} />
              </label>
            </div>

            <div className="flex flex-wrap gap-2 mt-2">
              {selectedPreviewImageUrl.length !== 0 ?
                selectedPreviewImageUrl.map((url, key) => {
                  return (
                    <div key={key} className="relative overflow-hidden">
                      <img className="w-20 h-20 rounded-md" src={url} alt="" />
                    </div>
                  )
                })
                :
                oldImagesUrl.map((url, key) => {
                  return (
                    <div key={key} className="relative overflow-hidden">
                      <img className="w-20 h-20 rounded-md" src={`https://store-api.qafilaty.com/images/${url}`} alt="" />
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className='pt-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900">{t('content')}</label>
            <CKEditor editor={ClassicEditor} data={defaultContent} className="h-48" onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setValue('content', data); // Set the content in React Hook Form
            }} />
          </div>

          <div className='flex justify-end pt-5'>
            <button className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
              </svg>

              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </form>
      }
    </section>
  )
}

export default EditBlog