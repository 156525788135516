import { gql } from '@apollo/client';

export const CreatePaymentMethod = gql`
mutation CreatePaymentMethod($content: contentPaymentMethod!) {
  createPaymentMethod(content: $content) {
    id
    bankName
    accountName
    number
    IBAN
    createdAt
    updatedAt
    deletedAt
  }
}
`;