import { gql, useLazyQuery } from "@apollo/client"
import { FetchSubscriptionsStores } from "../../types";

export const ALL_SUBSCRIPTION_STORES = gql`
query AllSubscribersStores {
  allSubscribersStores {
    id
    name
    link
    logo
    listSubscription {
      subscription {
        id
        planName
        price
        discount
        features
        published
        createdAt
        updatedAt
        deletedAt
      }
      startDate
      endDate
      price
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllSubscriptionsStores = () => {
  return useLazyQuery<FetchSubscriptionsStores>(ALL_SUBSCRIPTION_STORES);
}