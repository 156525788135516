import { gql, useLazyQuery } from "@apollo/client"
import { FetchDeliveryMethods } from "../../types";

export const ALL_DELIVERY_METHODS = gql`
query AllDeliveryMethods($idStore: ID) {
  allDeliveryMethods(idStore: $idStore) {
    id
    name
    price
    paymentWhenReceiving
    pricePaymentWhenReceiving
    estimatedTime
    cities
    createdAt
    updatedAt
    deletedAt
  }
}
`
export const useAllDeliveryMethods = () => {
  return useLazyQuery<FetchDeliveryMethods>(ALL_DELIVERY_METHODS, {
    fetchPolicy: 'cache-and-network',
  });
}