import { gql, useLazyQuery } from "@apollo/client"
import { FetchStatistics } from "../../types";

export const ALL_STATISTICS_STORE = gql`
query GeneralStatistics {
  generalStatistics {
    numberUsers
    numberOrders
    numberProducts
    numberStores
    saleStatistics {
      day
      totalSales
    }
  }
}
`
export const useStatistics = () => {
  return useLazyQuery<FetchStatistics>(ALL_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}