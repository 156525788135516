import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import i18n, { t } from 'i18next';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  const [direction, setDirection] = useState(i18n.dir());

  useEffect(() => {
    const handleLanguageChange = () => {
      setDirection(i18n.dir());
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <div className="flex flex-wrap items-center p-4">
      <ul className="flex items-center">
        {breadcrumbs.map(({ match, breadcrumb }: any, index) => {
          const isEditPath = match.pathname.includes('/edit/');
          const isView = match.pathname.includes('/view/');
          const isStatistics = match.pathname.includes('/statistics/');

          const breadcrumbText = typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.props.children;

          const breadcrumbParts = breadcrumbText.split('/');
          const lastPart = breadcrumbParts[breadcrumbParts.length - 1].trim();
          const hasId = lastPart.match(/[a-zA-Z0-9]+/);

          // Combine the breadcrumb parts without the ID for "edit" paths
          const editedBreadcrumb = isEditPath || isView || isStatistics && hasId ? breadcrumbParts.slice(0, -1).join(' / ') : breadcrumbText;

          return (
            <li key={match.pathname} className="inline-flex items-center">
              {match.pathname === "/"
                ?
                <Link to="/home" className="font-semibold text-gray-500 hover:text-primary">
                  <svg className="w-5 h-auto text-gray-400 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                  </svg>
                </Link>
                :
                editedBreadcrumb === "Edit" || editedBreadcrumb === "View" || editedBreadcrumb === "Statistics" || breadcrumbs.length - 1 === index ?
                  <p className="font-semibold text-gray-500">
                    {t(editedBreadcrumb.toLowerCase())}
                  </p>
                  :
                  <Link to={match.pathname} className="font-semibold text-gray-500 hover:text-primary">
                    {t(editedBreadcrumb.toLowerCase())}
                  </Link>
              }

              {
                match.pathname.includes('/edit') || match.pathname.includes('/view') || match.pathname.includes('/statistics') ?
                  null
                  :
                  breadcrumbs.length - 1 !== index ?
                    <svg className={`w-5 h-auto mx-1 ${direction === "rtl" && "rotate-180"} text-gray-400 fill-current`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                    </svg>
                    : null
              }
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;