import { gql } from "@apollo/client"

export const CreateStore = gql`
mutation CreateStore($content: contentStore!) {
  createStore(content: $content) {
    id
    name
    link
    logo
    icon
    description
    address
    createdAt
    updatedAt
    deletedAt
  }
}
`