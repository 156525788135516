import { gql, useLazyQuery } from "@apollo/client"
import { FetchClasses } from "../../types";

export const ALL_CLASS_FIRST_LEVEL = gql`
query AllClassFirstLevels($idStore: ID) {
  allClassFirstLevels(idStore: $idStore) {
    id
    name
    createdAt
    updatedAt
    deletedAt
    classSecondLevel {
      classThirdLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
        classSecondLevel {
          id
        }
      }
      createdAt
      deletedAt
      id
      name
      updatedAt
    }
  }
}
`

export const useAllClassFirstLevel = () => {
  return useLazyQuery<FetchClasses>(ALL_CLASS_FIRST_LEVEL, {
    fetchPolicy: 'cache-and-network',
  });
}