import { gql, useLazyQuery } from "@apollo/client"
import { FetchClients } from "../../types";

export const ALL_ClIENTS = gql`
query ClientsAllStores {
  clientsAllStores {
    id
    name
    email
    phone
    address
    gander
    note
    dateBirth
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllClients = () => {
  return useLazyQuery<FetchClients>(ALL_ClIENTS);
}