import { gql, useLazyQuery } from "@apollo/client"
import { FetchStores } from "../../types";

export const ALL_STORES = gql`
query AllStores {
  allStores {
    id
    name
    link
    logo
    icon
    description
    address
    numberProducts
    numberOrders
    numberClients
    admin {
      id
      profilePicture
      name
      email
      phone
      role
      activation
      emailVerify
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`
export const useAllStores = () => {
  return useLazyQuery<FetchStores>(ALL_STORES);
}