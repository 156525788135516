import { gql, useLazyQuery } from "@apollo/client"
import { FetchLinkStore, FetchStore } from "../../types";

export const StoreLink = gql`
query StoreByLink($link: String) {
  storeByLink(link: $link) {
    id
    name
    link
    logo
    icon
    description
    address
    welcomeMessage
    cssFile
    numberProducts
    numberOrders
    numberClients
    admin {
      id
      profilePicture
      name
      email
      phone
      role
      store {
        id
        name
        link
        logo
        icon
        description
        address
        welcomeMessage
        cssFile
        numberProducts
        numberOrders
        numberClients
        createdAt
        updatedAt
        deletedAt
      }
      activation
      emailVerify
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useStoreByLink = () => {
  return useLazyQuery<FetchLinkStore>(StoreLink);
}