import { gql, useLazyQuery } from "@apollo/client"
import { FetchBestStores } from "../../types";

export const BEST_THREE_STORE = gql`
query BestThreeStores {
  bestThreeStores {
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        permissions {
          id
          name
          permissions
          createdAt
          updatedAt
          deletedAt
        }
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    totalOrders
  }
}
`

export const useBestStores = () => {
  return useLazyQuery<FetchBestStores>(BEST_THREE_STORE);
}