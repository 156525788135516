import React from 'react'
import StoresDropDown from '../DropDowns/StoresDropDown'
import { FetchStores, StoresProps, checkedItems } from '../../types'
import { t } from 'i18next'

interface Props {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allStores?: FetchStores
  searchQuery: string
  direction: string
  searchResult: StoresProps[] | undefined
  startingIndex: number
  setAddAdminPopup: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedStoreId: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const StoresTable = ({ checkedItems, setCheckedItems, direction, allStores, searchQuery, searchResult, startingIndex, setAddAdminPopup, setSelectedStoreId, setDeleteConfirmPopup }: Props) => {

  const handleCheckAll = () => {
    if (allStores?.allStores.length !== checkedItems.length) {
      allStores !== undefined && setCheckedItems(allStores?.allStores.map(store => ({ id: store.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <td className="px-4 py-3 text-sm font-normal text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allStores?.allStores.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </td>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('products')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('clients')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('orders')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('address')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {searchQuery !== "" ?
          searchResult?.map((store: any) => (
            <tr key={store.id}>
              <td className="px-4 py-3 text-sm font-normal text-left text-gray-500 rtl:text-right">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === store.id)} onChange={() => handleCheck(store.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.name}
              </td>

              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberProducts}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberClients}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberOrders}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.address === null ? "N/A" : store.address}
              </td>

              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                <StoresDropDown direction={direction} admin={store.admin} setAddAdminPopup={setAddAdminPopup} id={store.id} setSelectedStoreId={setSelectedStoreId} setDeleteConfirmPopup={setDeleteConfirmPopup} />
              </td>
            </tr>
          ))
          :
          allStores?.allStores.slice(startingIndex, startingIndex + 10).map(store => (
            <tr key={store.id}>
              <td className="px-4 py-3 text-sm font-normal text-left text-gray-500 rtl:text-right">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === store.id)} onChange={() => handleCheck(store.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.name}
              </td>

              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberProducts}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberClients}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.numberOrders}
              </td>
              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {store.address === null ? "N/A" : store.address}
              </td>

              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                <StoresDropDown direction={direction} admin={store.admin} setAddAdminPopup={setAddAdminPopup} id={store.id} setSelectedStoreId={setSelectedStoreId} setDeleteConfirmPopup={setDeleteConfirmPopup} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default StoresTable