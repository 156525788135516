import { gql } from '@apollo/client';

export const CreateClassFirstLevel = gql`
mutation CreateClassFirstLevel($content: contentClassFirstLevel!) {
  createClassFirstLevel(content: $content) {
    id
    name
    classSecondLevel {
      id
      name
      classThirdLevel {
        id
        name
        classSecondLevel {
          id
        }
        createdAt
        updatedAt
        deletedAt
      }
      classFirstLevel {
        id
      }
      createdAt
      updatedAt
      deletedAt
    }
    store {
      id
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`