import React, { useEffect, useState } from 'react'
import { Input } from '../../../types';
import FailAlert from '../../../components/Alerts/FailAlert';
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';

interface OptionPopupProps {
  setOptionsPopup: React.Dispatch<React.SetStateAction<boolean>>
  options: Input[]
  onSave: (inputs: Input[]) => void
}

const OptionsPopup = ({ setOptionsPopup, options, onSave }: OptionPopupProps) => {

  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [tempOptions, setTempOptions] = useState<Input[]>([
    { name: '', subOptions: [{ name: '', price: 0, quantity: 0 }] }
  ])

  useEffect(() => {
    const hasChanged = JSON.stringify(options) !== JSON.stringify(tempOptions); // Use a deep comparison if necessary

    hasChanged && setTempOptions(JSON.parse(JSON.stringify(options)))
  }, [options])

  const addField = () => {
    setTempOptions([...tempOptions, { name: '', subOptions: [{ name: '', price: 0, quantity: 0 }] }]);
  };

  const addSubInput = (index: number) => {
    const updatedInputs = [...tempOptions];
    updatedInputs[index].subOptions.push({ name: '', price: 0, quantity: 0 });
    setTempOptions(updatedInputs);
  };

  const handleChange = (index: number, value: string) => {
    const updatedInputs = [...tempOptions];
    updatedInputs[index].name = value;
    setTempOptions(updatedInputs);
  };

  const handleSubInputChange = (index: number, subIndex: number, value: string) => {
    const updatedInputs = [...tempOptions];
    updatedInputs[index].subOptions[subIndex].name = value;
    setTempOptions(updatedInputs);
  };

  const handleSubPriceInputChange = (index: number, subIndex: number, value: number) => {
    const updatedInputs = [...tempOptions];
    updatedInputs[index].subOptions[subIndex].price = value;
    setTempOptions(updatedInputs);
  };

  const handleSubQuantityInputChange = (index: number, subIndex: number, value: number) => {
    const updatedInputs = [...tempOptions];
    updatedInputs[index].subOptions[subIndex].quantity = value;
    setTempOptions(updatedInputs);
  };

  const deleteField = (index: any) => {
    if (tempOptions.length > 1) {
      const updatedFields = tempOptions.filter((_: any, i: any) => i !== index);
      setTempOptions(updatedFields);
    }
  };

  const deleteSubInput = (Index: number, SubIndex: number) => {
    if (tempOptions[Index].subOptions.length > 1) {
      const updatedInputs = tempOptions.map((input, index) => {
        if (index === Index) {
          return {
            ...input,
            subOptions: input.subOptions.filter((_, subIndex) => subIndex !== SubIndex)
          };
        }
        return input;
      });
      setTempOptions(updatedInputs);
    }
  };

  const save = () => {
    for (const input of tempOptions) {
      if (input.name.trim() === '') {
        setFailMsg(t('formNonComplete'));
        setFail(true)
        return;
      }

      for (const subInput of input.subOptions) {
        if (subInput.name.trim() === '') {
          setFailMsg(t('formNonComplete'));
          setFail(true)
          return;
        }
      }

      for (const subInput of input.subOptions && input.subOptions) {
        if (subInput.price <= 0 || subInput.quantity <= 0) {
          setFailMsg(t('formNonComplete'));
          setFail(true)
          return;
        }
      }
    }

    onSave(tempOptions);
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-4/6'>

        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setOptionsPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='mb-5'>
          {tempOptions.map((option, index) => (
            <div key={index} className='mb-4'>
              <div className='flex items-center gap-2'>
                <div className="flex-1">
                  <input type="text" value={option.name} onChange={(e) => handleChange(index, e.target.value)} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('name')} />
                </div>

                <div className='flex items-center gap-2'>
                  <div onClick={() => deleteField(index)} className='bg-[#FFE3E3] text-red-500 my-2 p-2 cursor-pointer rounded-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                    </svg>
                  </div>
                  <div onClick={() => { addSubInput(index) }} className='p-2 my-2 text-white rounded-md cursor-pointer bg-primary'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                      <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              {option.subOptions.map((subOption, subIndex) => (
                <div className="ml-5" key={subIndex}>
                  <div>
                    <div className='flex items-center'>
                      <div className="flex flex-1 gap-2">
                        <input type="text" value={subOption.name} onChange={(e) => handleSubInputChange(index, subIndex, e.target.value)} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('option')} required />
                        <input type="number" value={subOption.price} onChange={(e) => handleSubPriceInputChange(index, subIndex, Number(e.target.value))} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('price')} required />
                        <input type="number" value={subOption.quantity} onChange={(e) => handleSubQuantityInputChange(index, subIndex, Number(e.target.value))} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('quantity')} required />
                      </div>
                      <div onClick={() => { deleteSubInput(index, subIndex) }} className='bg-[#FFE3E3] text-red-500 m-2 p-2 cursor-pointer rounded-md'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                          <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
          )}

          <div className='my-4'>
            <div>
              <div onClick={addField} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('addOption')}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-end pt-5'>
          <button onClick={save} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
              <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
            </svg>

            <span className="mx-2">
              {t('save')}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default OptionsPopup