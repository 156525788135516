import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import { t } from 'i18next'
import DomainRequestTable from '../../components/Tables/DomainRequestTable'
import { useAllDomains } from '../../graphql'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import { checkedItems } from '../../types'

const DomainRequests = () => {
  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [allDomains, { loading, data: dataAllDomains }] = useAllDomains();

  useEffect(() => {
    (async () => {
      await allDomains()

      setInitialLoading(false)
    })()
  }, []);

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='mx-6 my-4'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('domainRequest')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='relative p-2 mt-3 bg-white rounded-md'>
          <div className='pt-2 overflow-x-scroll'>
            <DomainRequestTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} allDomains={dataAllDomains} setFailMsg={setFailMsg} />
          </div>

          {dataAllDomains?.allDomains.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('No Domains to show')}</span>
            </div>
          }
        </div>
      }
    </section>
  )
}

export default DomainRequests