import React from 'react'
import UsersDropDown from '../DropDowns/UsersDropDown'
import { FetchUsers, UsersProps, checkedItems } from '../../types'
import { t } from 'i18next'

interface Props {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allUsers?: FetchUsers
  searchQuery: string
  direction: string
  searchResult: UsersProps[] | undefined
  startingIndex: number
  setSelectedUserId: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const UsersTable = ({ checkedItems, setCheckedItems, direction, allUsers, searchQuery, searchResult, startingIndex, setSelectedUserId, setDeleteConfirmPopup }: Props) => {

  const handleCheckAll = () => {
    if (allUsers?.usersAllStores.length !== checkedItems.length) {
      allUsers !== undefined && setCheckedItems(allUsers?.usersAllStores.map(user => ({ id: user.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <td className="px-4 py-4 text-sm font-normal text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allUsers?.usersAllStores.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </td>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('name')}
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('store')}
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('phone')}
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('email')}
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('Register date')}
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {searchQuery !== "" ?
          searchResult?.map(user => (
            <tr key={user.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === user.id)} onChange={() => handleCheck(user.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-4 py-4 text-sm">
                <h2 className="text-gray-800">{user?.name}</h2>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">
                  {user.store?.name}
                </p>
              </td>

              <td className="px-4 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">{user?.phone}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{user?.email}</p>
              </td>
              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{new Date(user.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <UsersDropDown direction={direction} setSelectedUserId={setSelectedUserId} setDeleteConfirmPopup={setDeleteConfirmPopup} id={user.id} />
              </td>
            </tr>
          ))
          :
          allUsers?.usersAllStores.slice(startingIndex, startingIndex + 10).map(user => (
            <tr key={user.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === user.id)} onChange={() => handleCheck(user.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-4 py-4 text-sm">
                <h2 className="text-gray-800">{user?.name}</h2>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">
                  {user.store?.name}
                </p>
              </td>

              <td className="px-4 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">{user?.phone}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{user?.email}</p>
              </td>
              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{new Date(user.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <UsersDropDown direction={direction} setSelectedUserId={setSelectedUserId} setDeleteConfirmPopup={setDeleteConfirmPopup} id={user.id} />
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default UsersTable