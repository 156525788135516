import { gql, useLazyQuery } from "@apollo/client"
import { FetchBrands } from "../../types";

export const ALL_BRANDS = gql`
query AllBrands($idStore: ID) {
  allBrands(idStore: $idStore) {
    id
    name
    image
    classFirstLevel {
      id
      name
      classSecondLevel {
        id
        name
        classThirdLevel {
          id
          name
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllBrands = () => {
  return useLazyQuery<FetchBrands>(ALL_BRANDS, {
    fetchPolicy: 'cache-and-network',
  });
}