import { gql } from "@apollo/client"

export const CreateClient = gql`
mutation Mutation($content: contentClient!) {
  createClient(content: $content) {
    id
    name
    email
    phone
    dateBirth
    address
    gander
    note
    createdAt
    updatedAt
    deletedAt
  }
}
`