import { gql, useLazyQuery } from "@apollo/client"
import { FetchClient } from "../../types";

export const Client = gql`
query Client($clientId: ID) {
  client(id: $clientId) {
    id
    profilePicture
    name
    email
    phone
    dateBirth
    address
    gander
    note
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
      client {
        id
        profilePicture
        name
        email
        phone
        dateBirth
        address
        gander
        note
        createdAt
        updatedAt
        deletedAt
      }
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      numberProducts
      numberOrders
      numberClients
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useClient = () => {
  return useLazyQuery<FetchClient>(Client);
}