import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
query CurrentUser {
  currentUser {
    id
    profilePicture
    name
    email
    phone
    role
    store {
      id
      link
      welcomeMessage
      name
    }
    activation
    emailVerify
    createdAt
    updatedAt
    deletedAt
  }
}
`;