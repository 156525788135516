import { gql } from "@apollo/client"

export const CreateDelivery = gql`
mutation CreateDelivery($content: contentDelivery!) {
  createDelivery(content: $content) {
    id
    discount
    coupon
    orderNumber
    note
    store {
      id
      name
      link
      logo
      icon
      description
      address
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    paymentMethod {
      id
      bankName
      accountName
      number
      IBAN
      country {
        id
        name
        currency
        asDefault
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    deliveryProduct {
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        imagesProduct {
          id
          name
          alt
          createdAt
          updatedAt
          deletedAt
        }
        brand {
          id
          name
          image
          classFirstLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        productOptions {
          id
          name
          subOptions {
            id
            name
            price
            quantity
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      price
      quantity
    }
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    deliveryMethod {
      id
      name
      price
      paymentWhenReceiving
      pricePaymentWhenReceiving
      estimatedTime
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`