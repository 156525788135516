import { gql } from "@apollo/client"

export const AuthenticateUser = gql`
mutation AuthenticateUser($content: loginInfo) {
  authenticateUser(content: $content) {
    token
    user {
      id
      name
    }
  }
}
`