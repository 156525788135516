import React, { useEffect, useState } from 'react'
import { FetchDeliveryMethod, StoreProps } from '../../../types';
import { useAllDeliveryMethods } from '../../../graphql';
import LoadingWidget from '../../LoadingWidget/LoadingWidget';
import { t } from 'i18next';

interface Tab3Props {
  prevPage: () => void,
  nextPage: () => void,
  deliveryMethod: FetchDeliveryMethod | null,
  setDeliveryMethod: React.Dispatch<React.SetStateAction<FetchDeliveryMethod | null>>,
  selectedStore: StoreProps | null
  setAddShippingPopup: React.Dispatch<React.SetStateAction<boolean>>,
}

const Tab3 = ({
  prevPage,
  nextPage,
  deliveryMethod,
  setDeliveryMethod,
  selectedStore,
  setAddShippingPopup
}: Tab3Props) => {

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [AllDeliveryMethods, { loading, data: dataAllDeliveryMethods }] = useAllDeliveryMethods();

  useEffect(() => {
    selectedStore &&
      AllDeliveryMethods({
        variables: { idStore: selectedStore.id }
      })

    setInitialLoading(false)
  }, [selectedStore]);

  // This code is for choose the first method by default 
  /*   useEffect(() => {
      if (allDeliveryMethods?.allDeliveryMethods !== undefined) {
        if (allDeliveryMethods.allDeliveryMethods.length > 0) {
          setDeliveryMethod(allDeliveryMethods?.allDeliveryMethods[0])
        }
      }
    }, [allDeliveryMethods]); */

  const selectMethod = (method: any) => {
    setDeliveryMethod(method)
  }

  return (
    <div className='my-3'>
      {initialLoading || loading ?
        <LoadingWidget />
        :
        dataAllDeliveryMethods?.allDeliveryMethods.length === 0 ?
          <div className='flex flex-col items-center justify-center w-full'>
            <div className='mx-2 bg-orange-400 rounded-2xl'>
              <div className='m-2 bg-white p-1.5 text-orange-400 rounded-full'>
                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 301.691 301.691">
                  <g>
                    <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0 	" />
                    <rect x="130.563" y="261.168" width="40.525" height="40.523" />
                  </g>
                </svg>
              </div>
            </div>

            <h3 className='my-3 text-xl'>{t('Dont Have Delivery Method')}</h3>

            <div>
              <div onClick={() => setAddShippingPopup(true)} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow cursor-pointer bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                <span className="mx-2">
                  {t('add')}
                </span>
              </div>
            </div>

          </div>
          :
          <>
            <div className='grid grid-cols-2 gap-6 md:grid-cols-3'>
              {dataAllDeliveryMethods?.allDeliveryMethods.map(method => (
                <div key={method.id} onClick={() => { selectMethod(method) }} className={`inline-block border ${deliveryMethod?.id === method.id && 'border-rose-600'} py-4 px-6 cursor-pointer rounded-md`}>
                  <div className="bg-rose-100 text-rose-600 inline-block p-2.5 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="m20.772 10.156-1.368-4.105A2.995 2.995 0 0 0 16.559 4H7.441a2.995 2.995 0 0 0-2.845 2.051l-1.368 4.105A2.003 2.003 0 0 0 2 12v5c0 .753.423 1.402 1.039 1.743-.013.066-.039.126-.039.195V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2h12v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2.062c0-.069-.026-.13-.039-.195A1.993 1.993 0 0 0 22 17v-5c0-.829-.508-1.541-1.228-1.844zM4 17v-5h16l.002 5H4zM7.441 6h9.117c.431 0 .813.274.949.684L18.613 10H5.387l1.105-3.316A1 1 0 0 1 7.441 6z"></path>
                      <circle cx="6.5" cy="14.5" r="1.5"></circle>
                      <circle cx="17.5" cy="14.5" r="1.5"></circle>
                    </svg>
                  </div>
                  <h3 className='mt-3 text-xl font-semibold'>{method.name}</h3>
                  <p className='mt-2 text-sm text-gray-500'>{method.estimatedTime}</p>
                  <p className='text-red-500'>${method.price}</p>
                </div>
              ))}
            </div>

            <div className='flex justify-between my-2'>
              <div onClick={() => { prevPage() }} >
                <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('prev')}
                  </span>
                </button>
              </div>
              <div onClick={() => { nextPage() }} >
                <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('next')}
                  </span>
                </button>
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default Tab3