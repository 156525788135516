import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { io, Socket } from "socket.io-client";
import useStore from "../store/useStore";
import Emitter from "./Emitter";
import notificationSound from "../assets/Notification.mp3";
// import {AllNotifications} from "../graphql";

export default class SocketClient {
  static io?: Socket;
  id?: string;
  idUser?: string;

  notification = new Audio(notificationSound);

  constructor({ idUser }: any) {
    try {
      this.idUser = idUser
      const token = (useStore.getState() as any).token;
      const url = process.env.NEXT_PUBLIC_SOCKET_URL || "https://store-api.qafilaty.com";

      SocketClient.io = io(url, {
        path: "/socket.io",
        autoConnect: true,
        transports: ["websocket"],
        withCredentials: true,
        query: {
          userId: idUser,
        },
        auth: {
          token: token ? `${token}` : "",
        },
      });
    } catch (error) {
      alert(`Something went wrong; Can't connect to socket server`);
    }
  }

  disconnect() {
    SocketClient?.io?.disconnect();
  }

  connect(client: ApolloClient<NormalizedCacheObject> | undefined) {
    SocketClient?.io?.on("connect", async () => {
      this.id = SocketClient?.io?.id;
    });

    SocketClient?.io?.on("connect_error", (err) => {
      console.log("connect_error =>", new Error(err.toString()).message);
    });

    SocketClient?.io?.on("error", (error) => {
      console.log("Socket Client error", error);
    });

    SocketClient?.io?.on("newDomain", (data) => {
      this.notification.play();
      Emitter.emit("newDomain", data)
      return data
    });
  }
}


export const socket = (id: string) => {
  const socketClient = new SocketClient({
    idUser: id,
  });

  return { socketClient }
}