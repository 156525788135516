import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/originalLogo.png';

import { GrClose } from 'react-icons/gr';
import { CgHomeAlt } from 'react-icons/cg';
import { FaStore } from 'react-icons/fa';
import { TbUsers, TbSettingsDollar, TbWorldWww } from 'react-icons/tb';
import { PiPackageFill, PiUsersThreeBold } from 'react-icons/pi';
import { BiCartAdd, BiSolidBookContent } from 'react-icons/bi';
import { IoSettingsOutline } from 'react-icons/io5';
import { t } from 'i18next';
import Emitter from '../../lib/Emitter';

const SideBar = ({ menu, setMenu }: { menu: boolean, setMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    Emitter.on("newDomain", (data: any) => {
      //console.log(data);
      setShowNotification(true);
    })
  }, [])

  const [SelectedPage, setSelectedPage] = useState<string>("dashboard");

  return (
    <aside className={`${menu ? "w-full px-5 py-8" : "w-0"} duration-500 flex flex-col fixed md:w-64 h-screen bg-white border-r rtl:border-r-0 rtl:border-l overflow-hidden z-40`}>
      <button className='flex justify-end mb-3 text-sideBarText md:hidden' onClick={() => { setMenu(false) }}>
        <GrClose size={22} />
      </button>

      <div className='border-b border-gray-300 md:py-2'>
        <Link to='/home'>
          <div>
            <img src={Logo} className='h-16' alt="LOGO" />
          </div>
        </Link>
      </div>

      <div className="relative flex flex-col justify-between flex-1 mb-5 overflow-y-auto links mt-7 md:px-5">
        <nav className="flex-1 space-y-3 md:-mx-3">
          <Link to="/home" className={`flex items-center px-3 py-2 ${SelectedPage === 'dashboard' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("dashboard") }}>
            <CgHomeAlt size={22} />

            <span className="mx-2 font-semibold">{t('dashboard')}</span>
          </Link>

          <Link to="/stores" className={`flex items-center px-3 py-2 ${SelectedPage === 'stores' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("stores") }}>
            <FaStore size={22} />

            <span className="mx-2 font-semibold">{t('stores')}</span>
          </Link>

          <Link to="/users" className={`flex items-center px-3 py-2 ${SelectedPage === 'users' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("users") }}>
            <TbUsers size={22} />

            <span className="mx-2 font-semibold">{t('users')}</span>
          </Link>

          <Link to="/clients" className={`flex items-center px-3 py-2 ${SelectedPage === 'clients' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("clients") }}>
            <PiUsersThreeBold size={22} />

            <span className="mx-2 font-semibold">{t('clients')}</span>
          </Link>

          <Link to="/products" className={`flex items-center px-3 py-2 ${SelectedPage === 'products' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("products") }}>
            <PiPackageFill size={22} />

            <span className="mx-2 font-semibold">{t('products')}</span>
          </Link>

          <Link to="/orders" className={`flex items-center px-3 py-2 ${SelectedPage === 'orders' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("orders") }}>
            <BiCartAdd size={22} />

            <span className="mx-2 font-semibold">{t('orders')}</span>
          </Link>

          <Link to="/subscription_management" className={`flex items-center px-3 py-2 ${SelectedPage === 'subscriptions' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("subscriptions") }}>
            <TbSettingsDollar size={22} />

            <span className="mx-2 font-semibold">{t('subscriptionManagement')}</span>
          </Link>

          <Link to="/content_management" className={`flex items-center px-3 py-2 ${SelectedPage === 'content' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("content") }}>
            <BiSolidBookContent size={22} />

            <span className="mx-2 font-semibold">{t('contentManagement')}</span>
          </Link>

          <Link to="/domain_requests" className={`flex justify-between items-center px-3 py-2 ${SelectedPage === 'domainRequest' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("domainRequest"); setShowNotification(false) }}>
            <div className='flex items-center'>
              <TbWorldWww size={22} />
              <span className="mx-2 font-semibold">{t('domainRequest')}</span>
            </div>
            <div>
              {showNotification === true &&
                <div className='w-2.5 h-2.5 bg-red-600 rounded-full'></div>
              }
            </div>
          </Link>

          <Link to="/settings" className={`flex items-center px-3 py-2 ${SelectedPage === 'settings' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMenu(false); setSelectedPage("settings") }}>
            <IoSettingsOutline size={22} />

            <span className="mx-2 font-semibold">{t('settings')}</span>
          </Link>
        </nav>
      </div>
    </aside >
  )
}

export default SideBar