import React from 'react'
import OrdersDropDown from '../DropDowns/OrdersDropDown'
import { DeliveryProps, FetchDeliveries, checkedItems } from '../../types'
import { t } from 'i18next'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  searchQuery: string,
  direction: string,
  searchResult: DeliveryProps[] | undefined,
  allDeliveries?: FetchDeliveries,
  startingIndex: number
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const OrdersTable = ({ checkedItems, setCheckedItems, direction, searchQuery, searchResult, allDeliveries, startingIndex, setSelectedIdForDelete, setDeleteConfirmPopup }: TableProps) => {

  const handleCheckAll = () => {
    if (allDeliveries?.deliveriesAllStores.length !== checkedItems.length) {
      allDeliveries !== undefined && setCheckedItems(allDeliveries.deliveriesAllStores.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allDeliveries?.deliveriesAllStores.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="px-3 py-3 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
            {t('Order Number')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('Client Name')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('address')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('date')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('Payment')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('shipping')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('total')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {/* Show the Search result only when search keyword is set */}
        {searchQuery === "" ?
          allDeliveries?.deliveriesAllStores.slice(startingIndex, startingIndex + 10).map(order => {
            return (
              <tr key={order.id}>
                <td className="px-4 py-4 text-sm font-medium">
                  <div className='flex'>
                    <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === order.id)} onChange={() => handleCheck(order.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                    <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                </td>
                <td className="px-3 py-3 text-sm">
                  <div>
                    <h2 className="text-gray-700">{order.orderNumber}</h2>
                  </div>
                </td>
                <td className="px-3 py-3 text-sm">
                  <p className="text-gray-500">{order.addressClient?.client ? order.addressClient?.client?.name : "unknown"}</p>
                </td>
                <td className="px-3 py-4 text-sm whitespace-nowrap">
                  <p className="text-gray-500">
                    {order.addressClient?.address},
                    <span className='ml-1'>
                      {order.addressClient?.city}
                    </span>
                  </p>
                </td>
                <td className="px-3 py-4 text-sm">
                  <p className="text-gray-500">{new Date(order.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
                </td>

                <td className="px-3 py-4 text-sm whitespace-nowrap">
                  <p className="text-gray-500">{order.paymentMethod?.bankName ? order.paymentMethod?.bankName : "N/A"}</p>
                </td>

                <td className="px-3 py-4 text-sm">
                  <p className="text-gray-500">{order.deliveryMethod?.name ? order.deliveryMethod?.name : "N/A"}</p>
                </td>

                <td className="px-3 py-4 text-sm">
                  <p className="text-gray-500">
                    {order.deliveryMethod ?
                      order?.deliveryMethod?.price + order?.deliveryProduct?.reduce(
                        (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                      )
                      :
                      order?.deliveryProduct?.reduce(
                        (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                      )}
                  </p>
                </td>

                <td className="px-3 py-4 text-sm">
                  <OrdersDropDown direction={direction} id={order.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfirmPopup={setDeleteConfirmPopup} />
                </td>
              </tr>
            )
          })
          :
          searchResult?.map(order => (
            <tr key={order.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === order.id)} onChange={() => handleCheck(order.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className="px-3 py-3 text-sm">
                <div>
                  <h2 className="text-gray-700">{order.orderNumber}</h2>
                </div>
              </td>
              <td className="px-3 py-3 text-sm">
                <p className="text-gray-500">{order.addressClient?.client ? order.addressClient?.client?.name : "unknown"}</p>
              </td>
              <td className="px-3 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">
                  {order.addressClient?.address},
                  <span className='ml-1'>
                    {order.addressClient?.city}
                  </span>
                </p>
              </td>
              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(order.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{order.paymentMethod?.bankName ? order.paymentMethod?.bankName : "N/A"}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{order.deliveryMethod?.name ? order.deliveryMethod?.name : "N/A"}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">
                  {order.deliveryMethod ?
                    order?.deliveryMethod?.price + order?.deliveryProduct?.reduce(
                      (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                    )
                    :
                    order?.deliveryProduct?.reduce(
                      (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                    )}
                </p>
              </td>

              <td className="px-3 py-4 text-sm">
                <OrdersDropDown direction={direction} id={order.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfirmPopup={setDeleteConfirmPopup} />
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default OrdersTable