import { gql, useLazyQuery } from "@apollo/client"
import { FetchBlogs } from "../../types";

export const ALL_BLOGS = gql`
query AllBlogs {
  allBlogs {
    id
    title
    shortDescription
    description
    imagesBlog
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllBlogs = () => {
  return useLazyQuery<FetchBlogs>(ALL_BLOGS);
}