import React, { useEffect, useState } from 'react'
import { useAllStores } from '../../../graphql';
import LoadingWidget from '../../LoadingWidget/LoadingWidget';
import { StoresProps } from '../../../types';
import { t } from 'i18next';

interface Props {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setSelectedStore: React.Dispatch<React.SetStateAction<StoresProps | null>>
}

const Tab0 = ({ setActiveTab, setSelectedStore }: Props) => {

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allStores, { loading, data: dataAllStores }] = useAllStores();

  useEffect(() => {
    (async () => {
      await allStores();

      setInitialLoading(false)
    })()

  }, [allStores]);

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<StoresProps[] | undefined>([]);

  useEffect(() => {
    const filtered = dataAllStores?.allStores.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResult(filtered);
  }, [searchQuery, dataAllStores]);

  return (
    <div className='my-2'>
      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div>
          <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
            <div className="grid w-12 h-full text-gray-300 place-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>

            <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none peer" type="text" id="search" placeholder={t('searchSomething')} />
          </div>

          <div className='mt-2 overflow-x-scroll'>
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('name')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('products')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('clients')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('orders')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('address')}
                  </th>

                  <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                    {t('option')}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {searchQuery !== "" ?
                  searchResult?.map((store: any) => (
                    <tr key={store.id}>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.name}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberProducts}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberClients}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberOrders}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.address === null ? "N/A" : store.address}
                      </td>

                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        <button onClick={() => { setSelectedStore(store); setActiveTab(1) }} type='button' className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 md:my-0 hover:bg-primaryHover'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                            <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                          </svg>

                          <span className="mx-2">
                            {t('select')}
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                  :
                  dataAllStores?.allStores.map((store: any) => (
                    <tr key={store.id}>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.name}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberProducts}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberClients}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.numberOrders}
                      </td>
                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        {store.address === null ? "N/A" : store.address}
                      </td>

                      <td className='px-4 py-3 text-sm whitespace-nowrap'>
                        <button onClick={() => { setSelectedStore(store); setActiveTab(1) }} type='button' className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 md:my-0 hover:bg-primaryHover'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                            <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                          </svg>

                          <span className="mx-2">
                            {t('select')}
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {searchQuery !== "" &&
            searchResult !== undefined &&
            searchResult.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default Tab0