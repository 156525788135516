import React, { useEffect, useState } from 'react'
import ChartLine from './ChartLine';
import { t } from 'i18next';
import { useBestStores, useStatistics } from '../../graphql';
import { Link } from 'react-router-dom';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import Logo from '../../assets/originalLogo.png'

const Home = () => {

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [statistics, { data: dataStatistics }] = useStatistics();
  const [bestStores, { loading: loadingBestStores, data: dataBestStores }] = useBestStores();

  useEffect(() => {

    (async () => {
      await statistics()
      await bestStores()

      setInitialLoading(false)
    })()
  }, []);

  return (
    <section className='my-4 mx-7'>
      <div className="grid grid-cols-1 gap-5 mt-4 md:grid-cols-2 lg:grid-cols-3">
        <Link to="/users">
          <div className="w-full">
            <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div className="p-3 bg-indigo-600 bg-opacity-75 rounded-full">
                <svg className="w-8 h-8 text-white" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z" fill="currentColor" />
                  <path d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z" fill="currentColor" />
                  <path d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z" fill="currentColor" />
                  <path d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z" fill="currentColor" />
                  <path d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z" fill="currentColor" />
                  <path d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z" fill="currentColor" />
                </svg>
              </div>

              <div className="mx-5">
                <h4 className="text-2xl font-semibold text-gray-700">{initialLoading ? "-" : dataStatistics?.generalStatistics.numberUsers}</h4>
                <div className="text-gray-500">{t('users')}</div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/orders">
          <div className="w-full">
            <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div className="p-3 bg-orange-600 bg-opacity-75 rounded-full">
                <svg className="w-8 h-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z" fill="currentColor" />
                  <path d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z" fill="currentColor" />
                  <path d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z" fill="currentColor" />
                </svg>
              </div>

              <div className="mx-5">
                <h4 className="text-2xl font-semibold text-gray-700">{initialLoading ? "-" : dataStatistics?.generalStatistics.numberOrders}</h4>
                <div className="text-gray-500">{t('orders')}</div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/products">
          <div className="w-full">
            <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div className="p-3 bg-pink-600 bg-opacity-75 rounded-full">
                <svg className="w-8 h-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.99998 11.2H21L22.4 23.8H5.59998L6.99998 11.2Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
                  <path d="M9.79999 8.4C9.79999 6.08041 11.6804 4.2 14 4.2C16.3196 4.2 18.2 6.08041 18.2 8.4V12.6C18.2 14.9197 16.3196 16.8 14 16.8C11.6804 16.8 9.79999 14.9197 9.79999 12.6V8.4Z" stroke="currentColor" strokeWidth="2" />
                </svg>
              </div>

              <div className="mx-5">
                <h4 className="text-2xl font-semibold text-gray-700">{initialLoading ? "-" : dataStatistics?.generalStatistics.numberProducts}</h4>
                <div className="text-gray-500">{t('products')}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="grid-cols-1 gap-5 my-5 lg:grid md:grid-cols-3">
        <div className="flex flex-col col-span-1 bg-white rounded-lg shadow md:col-span-2 md:row-span-3">
          <div className="px-6 py-5 font-semibold border-b border-gray-100">{t('profit')}</div>
          <div className="flex-grow p-4">
            <div className="flex items-center justify-center h-full px-4 py-16 text-3xl font-semibold text-gray-400 bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">
              {dataStatistics &&
                <ChartLine dataStatistics={dataStatistics} />
              }
            </div>
          </div>
        </div>

        <div className="flex items-center gap-6 p-8 bg-white rounded-lg shadow">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 text-yellow-600 bg-yellow-100 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19.148 2.971A2.008 2.008 0 0 0 17.434 2H6.566c-.698 0-1.355.372-1.714.971L2.143 7.485A.995.995 0 0 0 2 8a3.97 3.97 0 0 0 1 2.618V19c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8.382A3.97 3.97 0 0 0 22 8a.995.995 0 0 0-.143-.515l-2.709-4.514zm.836 5.28A2.003 2.003 0 0 1 18 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.22 4h2.214l2.55 4.251zM10.819 4h2.361l.813 4.065C13.958 9.137 13.08 10 12 10s-1.958-.863-1.993-1.935L10.819 4zM6.566 4H8.78l-.76 3.804.02.004C8.025 7.872 8 7.932 8 8c0 1.103-.897 2-2 2a2.003 2.003 0 0 1-1.984-1.749L6.566 4zM10 19v-3h4v3h-4zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3H5v-7.142c.321.083.652.142 1 .142a3.99 3.99 0 0 0 3-1.357c.733.832 1.807 1.357 3 1.357s2.267-.525 3-1.357A3.99 3.99 0 0 0 18 12c.348 0 .679-.059 1-.142V19h-3z"></path>
            </svg>
          </div>
          <div>
            <span className="block text-2xl font-bold">{initialLoading ? "-" : dataStatistics?.generalStatistics.numberStores}</span>
            <span className="block text-gray-500">{t('stores')}</span>
          </div>
        </div>

        <div className="flex items-center gap-6 p-8 bg-white rounded-lg shadow">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 text-teal-600 bg-teal-100 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
            </svg>
          </div>
          <div>
            <span className="block text-2xl font-bold">
              {initialLoading ?
                "-"
                :
                dataStatistics?.generalStatistics.saleStatistics.length === 0 ?
                  "-"
                  :
                  "$" + dataStatistics?.generalStatistics.saleStatistics.reduce((sum, sales) => sum + sales.totalSales, 0)
              }
            </span>
            <span className="block text-gray-500">{t('profit')}</span>
          </div>
        </div>

        <div className="row-span-2 my-5 bg-white rounded-lg shadow lg:my-0">
          <div className="px-6 py-5 font-semibold border-b border-gray-100">
            <span>{t('Best Stores')}</span>
          </div>

          {initialLoading || loadingBestStores ?
            <LoadingWidget />
            :
            dataBestStores?.bestThreeStores.length === 0 ?
              <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                  <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                </svg>
                <span className='px-2'>{t('No Stores to show')}</span>
              </div>
              :
              <div className="overflow-y-auto">
                <ul className="p-6 space-y-6">
                  {dataBestStores?.bestThreeStores.map(store => (
                    <li key={store.store.id} className="flex items-center justify-between">
                      <div className='flex items-center gap-3'>
                        <div className="flex justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                          {store?.store.logo ?
                            <img src={`https://store-api.qafilaty.com/images/${store?.store.logo}`} alt="LOGO" />
                            :
                            <img src={Logo} alt="LOGO" />
                          }
                        </div>
                        <span className="text-gray-600">{store.store.name}</span>
                      </div>
                      <span className="font-semibold">{store.totalOrders}</span>
                    </li>
                  ))}
                </ul>
              </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Home